import { useQuery, gql } from '@apollo/client';

export function GetDataPointsByModuleTemetry() {
  const { data = {}, loading, error } = useQuery(GET_DATAPOINTBYCUSTOMER);
  return { loading, data: data.DataPointsByModuleTemetry || [], error };
}

const GET_DATAPOINTBYCUSTOMER = gql`
  query DataPointsByModuleTemetry {
    DataPointsByModuleTemetry {
      _id
      name
      customerId
      longitude
      latitude
      active
      module
      location
      time
      diameter
      threshold
      yValuesMax
    }
  }
`;
