const defaultState = {
  collapseNav: false,
  profile: false,
  resetPassword: false,
  setPassword: false,
};
const app = (state = defaultState, action) => {
  switch (action.type) {
    case 'app.collapsedNav':
      return Object.assign({}, state, { collapseNav: action.collapse });
    case 'app.profile':
      return Object.assign({}, state, { profile: action.profile });
    case 'app.resetPassword':
      return Object.assign({}, state, { resetPassword: !state.resetPassword });
    case 'app.setPassword':
      return Object.assign({}, state, { setPassword: action.SetPassword });
    default:
      return state;
  }
};

export default app;
