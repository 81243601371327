import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useOverview() {
  const {
    waterQuality: {
      filterTankLevel: {
        register: { year, week, day, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  let variables = { week, year, day };
  const { data = { DataTankLevelLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });

  let dataOverview = [];
  data.DataTankLevelLog.forEach((rows) => {
    if (rows.data_point !== dataPoint) return;
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview };
}

function skip(variables) {
  let skip = true;
  let { year, week, day } = variables;
  skip = ![year, week, day].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ResponseTankLevelLog($year: Int!, $week: Int!, $day: Int!) {
    DataTankLevelLog: ResponseTankLevelLog(year: $year, week: $week, day: $day) {
      _id
      year
      week
      day
      data_point
      data_value
      tank_level
      hours_ori
      hours_apo
    }
  }
`;
