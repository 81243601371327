import React from 'react';
import { useLocation } from 'react-router-dom';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Sider, Main } from '../components';
import { Log } from '../../gravity-filter/views/log';
import { GraphicGravityFilters } from '../../gravity-filter/views/historic-log';

const ROUTES = [
  {
    key: 'daily-graph',
    name: I18n('daily_graph', true),
    path: '/water-quality/gravity-filter/daily-graph',
    component: GraphicGravityFilters,
  },
  {
    key: 'table',
    name: I18n('data_table', true),
    path: '/water-quality/gravity-filter/table',
    component: Log,
  },
];

export function GravityFilter() {
  const { collapseNav } = useSelector(({ app }) => app);
  const { pathname } = useLocation();
  const location = pathname.split('/');
  return (
    <Layout>
      {!collapseNav && (
        <Sider
          ROUTES={ROUTES}
          defaultSelectedKeys={location[3]}
          title={I18n('gravity_filter', true)}
          key={'gravity-filter'}
        />
      )}
      <Main ROUTES={ROUTES} collapseNav={collapseNav} breadcrumb={location} />
    </Layout>
  );
}
