import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import LogoBase from 'assets/images/cube.png';
import { Link } from 'react-router-dom';

import './style.less';

export function Sider({ ROUTES, title, defaultSelectedKeys }) {
  const { name } = useSelector(({ customers: { data } }) => data);

  return (
    <Layout.Sider className="sider-menu">
      <div className="container-logo">
        <div className="bg-isoogo">
          <img src={LogoBase} alt="Logo" />
        </div>
        <h2>iRubik</h2>
        <h4>{name}</h4>
      </div>
      <Menu mode="inline" className="menu" defaultSelectedKeys={[defaultSelectedKeys]}>
        <Menu.ItemGroup className="menu-item-group-title" key={title} title={title}>
          {ROUTES.map(({ name, path, key }) => (
            <Menu.Item key={key} style={{ float: 'right' }}>
              <Link to={path}>{name}</Link>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    </Layout.Sider>
  );
}
