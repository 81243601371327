import { gql } from '@apollo/client';
import sha256 from 'sha256';
import client from 'apollo/apollo-client';

export async function login(email, password) {
  const passwordhex = sha256(password);
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: MUTATION_LOGIN,
        variables: { email, passwordhex },
      })
      .then(
        ({
          data: {
            login: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

const MUTATION_LOGIN = gql`
  mutation login($email: String!, $passwordhex: String!) {
    login(email: $email, passwordhex: $passwordhex) {
      error
      data {
        _id
        name
        username
        email
        roles
        language
        verified
        active
        type
      }
      message
    }
  }
`;
