import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { I18n } from 'i18n';
import { ContentNotFound, ErrorPages, H2, P } from './style';

export function PagesError404() {
  return (
    <ContentNotFound>
      <ErrorPages />
      <H2>{I18n('error_pages', true)}</H2>
      <P>{I18n('error_pages_message', true)} </P>
      <Button type="primary">
        <Link to="/">{I18n('return_home', true)}</Link>
      </Button>
    </ContentNotFound>
  );
}
