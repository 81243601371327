import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function getThresholds() {
  return await client.query({
    query: GET_THRESHOLDS,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
}

export const GET_THRESHOLDS = gql`
  query ThresholdsCustomer {
    ThresholdsCustomer {
      error
      data
      message
    }
  }
`;
