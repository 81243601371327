import React from 'react';
import { I18n } from 'i18n';
import { Input, Form } from 'antd';
import PropTypes from 'prop-types';

export const Password = (props) => {
  let {
    disabled,
    value,
    noFormItem,
    placeholder,
    name,
    className,
    size,
    formItemLayout,
    style,
    touched,
    error,
    stateName,
    label,
  } = props;

  if (noFormItem) {
    return (
      <Input
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        type="password"
        size={size || 'middle'}
        value={value}
        className={className}
        onChange={(e) =>
          props.onChange(stateName, e.target.value, getValidMaxLength(e.target.value))
        }
      />
    );
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      help={touched || error ? error : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      label={label ? label : `${I18n('password', true)}:*`}
      {...formItemLayout}
      style={{ ...style }}
      hasFeedback
    >
      <Input
        disabled={disabled}
        type="password"
        style={{ ...style }}
        size={size || 'middle'}
        placeholder={placeholder ? placeholder : I18n('password', true)}
        value={value}
        className={className}
        onChange={(e) =>
          props.onChange(stateName, e.target.value, getValidMaxLength(e.target.value))
        }
      />
    </Form.Item>
  );
};

function getValidMaxLength(value) {
  if (value) {
    if (/.{9,}/.test(value)) return true;
    else if (/.{4,}/.test(value)) return true;
  }
  return 0;
}

Password.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
