import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';
import sha256 from 'sha256';

export async function userConfigure(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_USERS,
        variables: { ...variables, password: sha256(variables.password || '') },
        skip: skip(variables),
        refetchQueries: ['UsersByCustomer', 'UserById'],
      })
      .then(
        ({
          data: {
            UserConfigure: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { name, nameUser, address, password, active } = variables;
  skip = ![name, nameUser, address, password, active].every((v) => v);
  return skip;
}

const CONFIGURE_USERS = gql`
  mutation UserConfigure(
    $userId: ID
    $name: String!
    $nameUser: String!
    $address: String!
    $password: String
    $roles: [String!]
    $active: Boolean!
  ) {
    UserConfigure(
      userId: $userId
      name: $name
      nameUser: $nameUser
      address: $address
      password: $password
      roles: $roles
      active: $active
    ) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;
