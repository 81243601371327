import { useHistory } from 'react-router-dom';
import { Spin, Descriptions, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { GetDeviceById } from 'apollo/configuration';
import { I18n } from 'i18n';
import { DevicesState } from '../components';
export function Detail({ codeId, onCloseDrawer }) {
  const history = useHistory();
  const { data: dataDeviceById, isLoading, error } = GetDeviceById(codeId);

  if (codeId && error) history.push('/error-server');

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(dataDeviceById.code);
  };

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item label={`${I18n('name', true)} `}>
              {dataDeviceById.name}
            </Descriptions.Item>
            <Descriptions.Item className="rk-descriptions-items" label={`${I18n('code', true)}`}>
              {dataDeviceById.code}
              <Button
                style={{ marginLeft: 'auto' }}
                icon={<FontAwesomeIcon icon={faCopy} />}
                size="large"
                onClick={handleCopyClick}
              />
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('data_points_name', true)}`}>
              {dataDeviceById.data_point.name}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('type', true)}`}>
              {I18n(dataDeviceById.type, true)}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('color', true)}`}>
              <div style={{ width: 15, height: 15, background: dataDeviceById.color }} />
            </Descriptions.Item>
          </Descriptions>
          <div className="rk-button-group">
            <DevicesState
              active={dataDeviceById.active}
              codeId={dataDeviceById._id}
              onCloseDrawer={(e) => onCloseDrawer(e)}
            />
          </div>
        </>
      )}
    </>
  );
}
