import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { GET_USERSBYID } from 'apollo/configuration';
import { Text, Email, SelectMultiple, FormCard, Select } from 'components';
import { I18n } from 'i18n';
import useComponents from 'hooks/useComponents';
import { Save } from '../components/user-create/save';

const stateName = 'configuration.user';

export function UserForm(props) {
  const { userId, onCloseDrawer } = props;
  const history = useHistory();
  const {
    configuration: { user },
  } = useComponents();
  const rolesByAccess = useSelector(({ roles }) => roles);
  const { onChange } = useComponents();

  const { data = { UserById: {} }, error } = useQuery(GET_USERSBYID, {
    variables: { userId },
    skip: false,
  });

  const listRolesWebs = useMemo(() => {
    let memoRolesWebs = [];
    rolesByAccess.forEach((data) => {
      if (data.access.includes('mobile_client')) return;
      memoRolesWebs.push({ value: data.value, name: data.name, label: I18n(data.name, true) });
    });
    return memoRolesWebs;
  }, [rolesByAccess]);

  const listRolesMobiles = useMemo(() => {
    let memoRolesMobiles = [];
    rolesByAccess.forEach((data) => {
      if (!data.access.includes('mobile_client')) return;
      memoRolesMobiles.push({ value: data.value, name: data.name, label: I18n(data.name, true) });
    });
    return memoRolesMobiles;
  }, [rolesByAccess]);

  const dataAccesUserByWeb = useMemo(() => {
    let accesWeb = '';
    if (data.UserById?.roles) {
      listRolesWebs.forEach(({ value }) => {
        if (data.UserById?.roles.includes(value)) return (accesWeb = value);
      });
    }
    return accesWeb;
  }, [data.UserById, listRolesWebs]);

  const dataAccesUserByMobile = useMemo(() => {
    let acces = [];
    if (data.UserById.roles) {
      const { roles = [] } = data.UserById;
      listRolesMobiles.forEach((data) => {
        if (!roles.includes(data.value)) return;
        acces.push(data.value);
      }, []);
    }
    return acces;
  }, [data.UserById, listRolesMobiles]);

  if (userId && error) history.push('/error-server');

  return (
    <>
      <Form layout="vertical">
        <FormCard
          title={I18n('basic_user_information')}
          description={I18n('configure_all_general_user_information')}
        >
          <Text
            value={user.name}
            default={data.UserById?.name}
            onChange={onChange}
            stateName={`${stateName}.name`}
            placeholder={`${I18n('name', true)} ${I18n('and', true)} ${I18n('last_name', true)}:*`}
            externalLabel
          />
          <Email
            disabled={!!userId}
            value={user.address}
            defaultValue={data.UserById.email}
            onChange={onChange}
            stateName={`${stateName}.address`}
            externalLabel
          />
          <Text
            value={user.address?.split('@')[0]}
            default={data.UserById?.username}
            disabled={true}
            onChange={onChange}
            stateName={`${stateName}.nameUser`}
            placeholder={`${I18n('name_user', true)}:*`}
            externalLabel
          />
        </FormCard>

        <FormCard
          title={`${I18n('permissions_web_applications')}:*`}
          description={I18n('configure_the_permissions_that_the_user_will_have')}
        >
          <Select
            default={dataAccesUserByWeb}
            setInput={onChange}
            data={listRolesWebs}
            style={{ width: '100%', marginTop: 8 }}
            externalLabel
            label={`${I18n('permissions_web_applications', true)}:*`}
            stateName={`${stateName}.accesWebs`}
          />
        </FormCard>
        <FormCard
          title={`${I18n('permissions_mobile_applications')}:*`}
          description={I18n('configure_the_permissions_that_the_user_will_have')}
        >
          <SelectMultiple
            defaultValue={dataAccesUserByMobile}
            setInput={onChange}
            data={listRolesMobiles}
            style={{ width: '100%', marginTop: 8 }}
            externalLabel
            label={`${I18n('permissions_mobile_applications', true)}:*`}
            stateName={`${stateName}.accessMobile`}
          />
        </FormCard>
      </Form>
      <div className="rk-button-group">
        <Save userId={userId} onCloseDrawer={onCloseDrawer} />
        {/* <State
              userId={userId}
              active={dataUserById.active}
              onCloseDrawer={() => onCloseDrawer()}
            /> */}
      </div>
    </>
  );
}
