import React from 'react';
import { useLocation } from 'react-router-dom';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Sider, Main } from '../components';
import { Log } from '../../filter-pressure/views/log';
import { IndividualGraphicLog } from '../../filter-pressure/views/historic-log';
import { MultipleGraphicLog } from '../../filter-pressure/views/historic-Multiple';

const ROUTES = [
  {
    key: 'daily-graph',
    name: I18n('daily_graph_multiple', true),
    path: '/water-quality/filter-pressure/daily-graph-multiple',
    component: MultipleGraphicLog,
  },
  {
    key: 'table',
    name: I18n('daily_graph_individual', true),
    path: '/water-quality/filter-pressure/daily-graph',
    component: IndividualGraphicLog,
  },
  { name: I18n('data_table', true), path: '/water-quality/filter-pressure/table', component: Log },
];

export function FilterPressure() {
  const { collapseNav } = useSelector(({ app }) => app);
  const { pathname } = useLocation();
  const location = pathname.split('/');
  return (
    <Layout>
      {!collapseNav && (
        <Sider
          ROUTES={ROUTES}
          defaultSelectedKeys={location[3]}
          title={I18n('filter_pressure')}
          key={'filter-pressure'}
        />
      )}
      <Main ROUTES={ROUTES} collapseNav={collapseNav} breadcrumb={location} />
    </Layout>
  );
}
