import React from 'react';
import { I18n } from 'i18n';
import { Spin, Table as TableUI } from 'antd';
import { Content, Container } from 'components';
import { days } from 'utils';
import Filters from './filter';
import { useOverview } from 'apollo/thermometers';

const stateName = 'environment.maxandmin.log';

export function Log() {
  let { data, loading } = useOverview();
  return (
    <Container>
      <Filters stateName={`${stateName}`} />
      <Content>
        <Spin spinning={loading}>
          <TableUI
            dataSource={data}
            bordered
            pagination={false}
            loading={loading}
            rowKey={(data) => data._id + data.day + data.hour}
            columns={columns(data)}
          />
        </Spin>
      </Content>
    </Container>
  );
}

function columns(data) {
  let columns = [];
  ['year', 'week'].forEach((key) =>
    columns.push({
      title: I18n(key, true),
      dataIndex: key,
      key,
      filters: findFilters(data, key),
      onFilter: (value, record) => record[key] === value,
    }),
  );
  columns.push({
    title: I18n('day', true),
    dataIndex: 'day',
    key: 'day',
    render: (text) => days[text],
    filters: findFiltersDays(data, 'day'),
    onFilter: (value, record) => record['day'] === value,
  });
  columns.push({
    title: I18n('hour', true),
    dataIndex: 'hours',
    key: 'hours',
    filters: findFilters(data, 'hours'),
    onFilter: (value, record) => record['hours'] === value,
  });
  columns.push({
    title: I18n('data_point', true),
    dataIndex: 'dataPoint',
    key: 'dataPoint',
  });
  columns.push({
    title: I18n('thermometer', true),
    dataIndex: 'name',
    key: 'name',
  });
  columns.push({
    title: I18n('max', true),
    dataIndex: 'max',
    key: 'max',
    filters: findFilters(data, 'max'),
    onFilter: (value, record) => record['max'] === value,
  });
  columns.push({
    title: I18n('min', true),
    dataIndex: 'min',
    key: 'min',
    filters: findFilters(data, 'min'),
    onFilter: (value, record) => record['min'] === value,
  });
  return columns;
}

function findFilters(data, key) {
  let out = data.map((item) => item[key]);
  out = [...new Set(out)].sort();
  return out.map((value) => ({ text: value, value }));
}

function findFiltersDays(data, key) {
  let out = data.map((item) => item[key]);
  out = [...new Set(out)].sort();
  return out.map((value) => ({ text: days[value], value }));
}
