import { gql, useMutation } from '@apollo/client';

export function useDeviceRemove(_id) {
  const variables = { _id };
  const [UserRemove, { data, loading, error }] = useMutation(REMOVE_USERS, {
    variables,
    skip: skip(variables),
    refetchQueries: ['UsersByCustomer'],
  });
  return {
    UserRemove,
    data: data?.DevicesRemove.data,
    loading,
    message: data?.DevicesRemove.message,
    error,
  };
}

function skip(variables) {
  let skip = true;
  let { _id } = variables;
  skip = ![_id].every((v) => v);
  return skip;
}

const REMOVE_USERS = gql`
  mutation DevicesRemove($_id: ID!) {
    DevicesRemove(_id: $_id) {
      error
      data {
        _id
        name
        code
        type
        location
        color
        data_points
        data_points_name
        active
      }
      message
    }
  }
`;
