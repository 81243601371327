import { gql } from '@apollo/client';
import sha256 from 'sha256';
import client from 'apollo/apollo-client';

const MUTATION_SETTOKENPASSWORD = gql`
  mutation setPassword($passhex: String!, $token: String!) {
    setPassword(passhex: $passhex, token: $token) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;

export async function setPassword(password, token) {
  const passwordhex = sha256(password);
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: MUTATION_SETTOKENPASSWORD,
        variables: { passhex: passwordhex, token },
      })
      .then(
        ({
          data: {
            setPassword: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}
