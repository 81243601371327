import React from 'react';
import { I18n } from 'i18n';
import { Select } from 'components';
import PropTypes from 'prop-types';

export default function SelectStartDate({
  years,
  months,
  weeks,
  days,
  hideWeek,
  hideDay,
  hideMonth,
  stateName,
  weeksRange,
  daysRange,
}) {
  const defaultWeeks = !weeksRange ? weeks.length : weeksRange;
  const defaultDays = !daysRange ? days.length : daysRange;
  return (
    <>
      <Select
        label={I18n('year')}
        stateName={`${stateName}.yearStart`}
        data={years}
        placeholder={I18n('year')}
        default={years[0]?.value}
        style={{ marginRight: 5 }}
      />
      {!hideMonth ? (
        <Select
          label={I18n('month')}
          stateName={`${stateName}.monthStart`}
          data={months}
          placeholder={I18n('month')}
          default={months[months.length - 1]?.value}
          style={{ marginRight: 5 }}
        />
      ) : null}
      {!hideWeek ? (
        <Select
          label={I18n('week')}
          stateName={`${stateName}.weekStart`}
          data={weeks}
          placeholder={I18n('week')}
          default={weeks[defaultWeeks - 1]?.value}
          style={{ marginRight: 5 }}
        />
      ) : null}
      {!hideDay ? (
        <Select
          label={I18n('day')}
          stateName={`${stateName}.dayStart`}
          data={days}
          placeholder={I18n('day')}
          default={days[defaultDays - 1]?.value}
          style={{ width: 100 }}
        />
      ) : null}
    </>
  );
}

SelectStartDate.propTypes = {
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
  days: PropTypes.array,
  stateName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hideWeek: PropTypes.bool,
  hideDay: PropTypes.bool,
  hideMonth: PropTypes.bool,
  weeksRange: PropTypes.number,
};
