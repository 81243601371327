import React from 'react';
import { Line } from 'components';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { hoursByDay } from 'utils';

export default function GraphicGravityFiltersLog(props) {
  const { data, loading } = props;
  
  const threshold = useSelector(
    ({
      customers: {
        data: {
          thresholds: { tank_levels },
        },
      },
    }) => tank_levels,
  );

  return (
    <Line
      loading={loading}
      data={data}
      thresholds={threshold}
      xAccessor="hour"
      yAccessor="value"
      axes={{
        left: {
          tickSize: 4,
          tickPadding: 2,
          tickRotation: 60,
          legend: `${I18n('volume', true)} [litros]`,
          legendOffset: -48,
          legendPosition: 'middle',
        },
        bottom: {
          tickValues: hoursByDay(60),
          tickSize: 10,
          tickRotation: 90,
          legend: I18n('hour', true),
          legendOffset: 55,
          legendPosition: 'middle',
        },
      }}
    />
  );
}
