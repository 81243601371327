import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ListCustormers } from './customers';
import { Login } from './login';
import { ResetPassword } from './resetPassword/resetPassword';
import { SetPassword } from './setPassword';

export const Account = () => {
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const { user, app } = useSelector((state) => state);
  let view = <Login />;
  if (app.resetPassword) view = <ResetPassword />;
  if (token) view = <SetPassword />;
  if (user.data._id) view = <ListCustormers />;
  return <>{view}</>;
};
