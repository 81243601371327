import { Spin as AntdSpin } from 'antd';
import './style.less';

export const Spin = (props) => {
  const { loading } = props;
  return (
    <div className="spin-center">
      <AntdSpin spinning={loading} />
    </div>
  );
};
