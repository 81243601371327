import { useSelector } from 'react-redux';
import { I18n } from 'i18n';
import { Table as TableUI, Spin } from 'antd';
import { InputRows, Container, Content } from 'components';
import { days } from 'utils';
import DateRange from '../../components/date-range';

import { useLog } from 'apollo/water-preparation';

const stateName = 'waterQuality.waterPreparantion.log';

export function Log() {
  const {
    waterPreparantion: { log },
  } = useSelector(({ state: { waterQuality } }) => waterQuality);

  const { data, loading } = useLog(log);

  return (
    <Container>
      <InputRows>
        <DateRange stateName={`${stateName}`} hideMonth hideDay />
      </InputRows>
      <Content>
        {loading && !data.length ? (
          <Spin spinning={loading} />
        ) : (
          <TableUI
            dataSource={data}
            bordered
            size="small"
            pagination={false}
            rowKey={(data) => data._id + data.day + data.hours}
            columns={columns(data)}
          />
        )}
      </Content>
    </Container>
  );
}

function columns(data = []) {
  let columns = [];
  ['year', 'week'].forEach((key) =>
    columns.push({
      title: I18n(key, true),
      dataIndex: key,
      key,
      filters: findFilters(data, key),
      onFilter: (value, record) => record[key] === value,
    }),
  );

  columns.push({
    title: I18n('day', true),
    key: 'day',
    dataIndex: 'day',
    render: (text) => days[text],
    filters: findFiltersDays(data, 'day'),
    onFilter: (value, record) => record['day'] === value,
  });

  columns.push({
    title: I18n('hour', true),
    key: 'hours',
    dataIndex: 'hours',
    filters: findFilters(data, 'hours'),
    onFilter: (value, record) => record['hours'] === value,
  });

  columns.push({
    title: I18n('user', true),
    key: 'user',
    dataIndex: 'user',
    render: (text) => I18n(text, true),
    filters: findFilters(data, 'user'),
    onFilter: (value, record) => record['user'] === value,
  });

  columns.push({
    title: I18n('data_point', true),
    key: 'name',
    dataIndex: 'name',
    filters: findFilters(data, 'name'),
    onFilter: (value, record) => record['name'] === value,
  });

  columns.push({
    title: I18n('water_quantity', true),
    key: 'quantityWater',
    dataIndex: 'quantityWater',
    filters: findFilters(data, 'quantityWater'),
    onFilter: (value, record) => record['quantityWater'] === value,
  });

  columns.push({
    title: I18n('dioxoro_quantity', true),
    key: 'quantityDioxoro',
    dataIndex: 'quantityDioxoro',
    filters: findFilters(data, 'quantityDioxoro'),
    onFilter: (value, record) => record['quantityDioxoro'] === value,
  });
  columns.push({
    title: I18n('ORP_value', true),
    key: 'valueORP',
    dataIndex: 'valueORP',
    filters: findFilters(data, 'valueORP'),
    onFilter: (value, record) => record['valueORP'] === value,
  });
  return columns;
}

function findFilters(data, key) {
  let out = data.map((item) => item[key]);
  out = [...new Set(out)].sort();
  return out.map((value) => ({ text: value, value }));
}

function findFiltersDays(data, key) {
  let out = data.map((item) => item[key]);
  out = [...new Set(out)].sort();
  return out.map((value) => ({ text: days[value], value }));
}
