import { useQuery, gql } from '@apollo/client';

export function useResponseDates() {
  const { data = { Dates: [] }, loading } = useQuery(GET_SUBQUERY);
  return { loading, data: { Dates: data.Dates } };
}

const GET_SUBQUERY = gql`
  query getSensoresDates {
    Dates: ResponsesSensorsDates {
      _id
      year
      week
      day
      month
    }
  }
`;
