import React from 'react';
import PropTypes from 'prop-types';
import './style.less';

export const Avatar = (props) => {
  return (
    <div className="rk-avatar-container">
      <div className="rk-avatar">{props.text[0]}</div>
      <p>{props.text}</p>
    </div>
  );
};

Avatar.propTypes = {
  text: PropTypes.string,
};
