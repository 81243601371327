import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function getAppParams() {
  return await client.query({ query: GET_APPPARAMS });
}

const GET_APPPARAMS = gql`
  query AppParams {
    Applications {
      _id
      name
      active
    }
    Roles {
      _id
      name
      active
      access
    }
    RoleByAccess {
      _id
      name
      active
      access
    }
  }
`;
