import React from 'react';
import { Loading, NoResults } from 'components';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import esES from 'antd/lib/locale-provider/es_ES';
import enUS from 'antd/lib/locale-provider/en_US';
import 'moment/locale/es';
import 'moment/locale/eu';
import { getLanguage } from '../utils';
import { useMe } from 'hooks/useMe';
import { useParams } from 'hooks/useParams';
import { Account } from './account';
import { AppLayout } from './layout';

const langObj = {
  en: enUS,
  es: esES,
};

export const App = () => {
  const { loading: isParamsLoading } = useParams();
  const { loading: isAppLoading } = useMe();
  const { user, customers } = useSelector(({ user, customers }) => ({ user, customers }));
  if (isAppLoading || isParamsLoading) return <Loading loading={isAppLoading || isParamsLoading} />;

  return (
    <div className="App">
      <Router>
        <ConfigProvider locale={langObj[getLanguage(user?.data)]} renderEmpty={NoResults}>
          {(user.data.roles || user.data.active) && customers.data._id ? (
            <AppLayout />
          ) : (
            <Account />
          )}
        </ConfigProvider>
      </Router>
    </div>
  );
};
