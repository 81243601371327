import { Form } from 'antd';
import { Email } from 'components';
import useComponents from 'hooks/useComponents';
import { Assing } from '../components';

const stateName = 'configuration.user';

export function AssignUser({ onCloseDrawer }) {
  const {
    configuration: { user },
  } = useComponents();

  const { onChange } = useComponents();

  return (
    <>
      <Form layout="vertical">
        <Email
          value={user.address}
          onChange={onChange}
          stateName={`${stateName}.address`}
          externalLabel
        />
        <div className="rk-button-group">
          <Assing onCloseDrawer={() => onCloseDrawer()} />
        </div>
      </Form>
    </>
  );
}
