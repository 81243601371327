import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Form, PageHeader, Spin, Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, Number } from 'components';
import { Select } from 'components';
import { I18n } from 'i18n';
import { redex, thresholdsColors } from 'utils';
import useComponents from 'hooks/useComponents';
import { getThresholds } from 'apollo/configuration';
import Save from '../components/save';

const stateName = 'configuration.threshold';

export function ThresholdsForm() {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);
  const location = useLocation();
  const { codeId } = queryString.parse(location.search);
  const valueByIndex = codeId.split('-');
  const {
    configuration: { threshold },
    onChange,
  } = useComponents();

  const thresholds = Object.values(result[threshold.name] || {}) || {};
  const thresholdData = thresholds[valueByIndex[1]] || [];
  const colors = Object.keys(thresholdsColors).map((key) => ({
    value: key,
    name: I18n(key, true),
  }));

  useEffect(() => {
    async function dataCustomers() {
      try {
        const { data, loading } = await getThresholds();
        setIsLoading(loading);
        setResult(data.ThresholdsCustomer.data);
      } catch (error) {
        setError(new Error(error.message));
      }
    }
    dataCustomers();
  }, []);

  if (error) return history.push('/error-server');

  return (
    <Container style={{ borderWidth: 2, borderRadius: 0, borderColor: '#d6d6d6' }}>
      <Spin spinning={isLoading}>
        <PageHeader
          backIcon={[
            <Button type="primary" icon={<FontAwesomeIcon icon={faArrowLeft} />} size="large" />,
          ]}
          onBack={() => history.push('/configuration/thresholds')}
          style={{ color: '#272e3a' }}
          title={I18n('update_thresholds', true, true)}
          extra={[<Save codeId={codeId} />]}
        />
        <Form
          layout="vertical"
          style={{
            flex: 1,
            margin: '2vh 10vw',
            justifyContent: 'center',
          }}
        >
          <Number
            defaultValue={thresholdData.min}
            value={threshold.min}
            onChange={onChange}
            re={redex.number}
            min={0}
            step={0.1}
            stateName={`${stateName}.min`}
            placeholder={I18n('minimum', true)}
            externalLabel
          />
          <Number
            defaultValue={thresholdData.max}
            value={threshold.max}
            disabled={thresholdData.max === 'Infinity' ? true : false}
            onChange={onChange}
            re={redex.number}
            stateName={`${stateName}.max`}
            placeholder={I18n('maximum', true)}
            externalLabel
            min={0}
            step={0.1}
          />
          <Form.Item label={I18n('color', true)}>
            <Select
              default={thresholdData.color}
              value={threshold.color}
              setInput={onChange}
              data={colors}
              stateName={`${stateName}.color`}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Container>
  );
}
