import React from 'react';
import { DatePicker as AndtDatePicker, Form } from 'antd';
import PropTypes from 'prop-types';

export const DatePicker = (props) => {
  if (props.noFormItem) {
    return (
      <AndtDatePicker
        disabled={props.disabled}
        style={props.style}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(range) => props.onChange(props.stateName, range)}
      />
    );
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      help={props.touched || props.error ? props.error : ''}
      validateStatus={props.touched || props.error ? 'error' : 'success'}
      style={props.formItemStyle}
      label={props.externalLabel ? props.placeholder : props.externalLabel}
    >
      <AndtDatePicker
        disabled={props.disabled}
        style={props.style}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(range) => props.onChange(props.stateName, range)}
      />
    </Form.Item>
  );
};

DatePicker.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
