import { useEffect } from 'react';
import { I18n } from 'i18n';
import { Select as AndtSelect, Form } from 'antd';
import PropTypes from 'prop-types';
import useComponents from 'hooks/useComponents';

const { Option, OptGroup } = AndtSelect;

export const Select = (props) => {
  let { onChange } = useComponents();
  let { data = [], valid, notables, showSearch, stateName, allowClear, loading } = props,
    label = props.label ? I18n(props.label, true) : null,
    options = [<Option key="EMPTY">{label}</Option>],
    alt_options = [],
    ui_options,
    defaultValue = props.default;

  useEffect(() => {
    if (props.default) {
      onChange(stateName, props.default, valid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default]);

  if (notables && notables.length) notables = notables.map((note) => note.value);
  else notables = [];
  if (data.length) {
    options = [];
    data.forEach((d, i) => {
      if (!d.value) return null;
      let style = {};
      if (props.altOptions && !notables.includes(d.name || d.value)) {
        alt_options.push(
          <Option style={style} value={d.value} key={`${d.name || d.value}-${i}`}>
            {d.name || d.value}
          </Option>,
        );
      } else {
        options.push(
          <Option style={style} value={d.value} key={`${d.name || d.value}-${i}`}>
            {d.name || d.value}
          </Option>,
        );
      }
    });

    if (props.all) {
      options.unshift(
        <Option disabled={true} key={-1}>
          ------
        </Option>,
      );
      options.unshift(
        <Option value={'__ALL__'} key={0}>
          {I18n('select_all', true)}
        </Option>,
      );
    }
  }

  let catOptions = options.concat(alt_options);
  if (catOptions.length < 2) {
    defaultValue = catOptions[0].props.value;
  }
  if (props.altOptions) {
    ui_options = [];
    if (label) {
      ui_options.push(
        <Option key={-2} disabled>
          {label}
        </Option>,
      );
    }
    if (options.length)
      ui_options.push(
        <OptGroup key={'Opt1'} label={I18n(props.keyOptions, true)}>
          {options}
        </OptGroup>,
      );
    if (alt_options.length)
      ui_options.push(
        <OptGroup key={'Opt2'} label={I18n(props.altOptions, true)}>
          {alt_options}
        </OptGroup>,
      );
  } else {
    ui_options = options;
    if (label) {
      ui_options.unshift(
        <Option key={-2} value="label" disabled>
          {label}
        </Option>,
      );
    }
  }
  if (!props.isFormItem) {
    return (
      <AndtSelect
        key={defaultValue}
        style={props.style}
        defaultValue={defaultValue}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        filterOption={(input, option) => {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(value) => onChange(stateName, value, valid)}
        disabled={props.disabled || catOptions.length < 2}
      >
        {ui_options}
      </AndtSelect>
    );
  }

  return (
    <Form.Item
      required={props.required}
      tooltip={props.tooltip}
      labelCol={{ span: 24 }}
      help={props.touched || props.error ? props.error : ''}
      validateStatus={props.touched || props.error ? 'error' : 'success'}
      style={props.formItemStyle}
      label={I18n(props.label, true)}
    >
      <AndtSelect
        key={defaultValue}
        style={props.style}
        defaultValue={defaultValue}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        filterOption={(input, option) => {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(value) => onChange(stateName, value, valid)}
        disabled={props.disabled || catOptions.length < 2}
      >
        {ui_options}
      </AndtSelect>
    </Form.Item>
  );
};

Select.propTypes = {
  altOptions: PropTypes.string,
  stateName: PropTypes.string,
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  data: PropTypes.array,
  default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
