import { useMemo } from 'react';
import { TemperatureMaxMin } from './thermometers';
import { Weather } from './weather';

export const useEnvironment = () => {
  return useMemo(() => {
    return [
      {
        name: 'max-and-min-temperature',
        exact: true,
        path: 'max-and-min-temperature',
        component: TemperatureMaxMin,
      },
      {
        name: 'temperature-and-humedity',
        exact: true,
        path: 'temperature-and-humedity',
        component: Weather,
      },
    ];
  }, []);
};
