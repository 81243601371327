import React from 'react';
import { componentsRedux } from 'redux/reusable';
import { useTemeperatureSubQuerys } from 'apollo/thermometers/getSubQueries';

const Subqueries = (props) => {
  const { yearStart, yearEnd, weekStart, weekEnd } = props;
  const { data, loading } = useTemeperatureSubQuerys({
    yearStart,
    yearEnd,
    weekStart,
    weekEnd,
  });
  return <div>{props.children({ loading, data: data.Subqueries })}</div>;
};

export default componentsRedux(Subqueries);
