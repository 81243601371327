import styled from 'styled-components';
import { Menu as AntdMenu, Layout as AntdLayout } from 'antd';

export const Layout = styled(AntdLayout)``;

export const Sider = styled(AntdLayout.Sider)`
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
  transition: none !important;
  position: relative;
  border-right: 1px solid #dde2ed !important;
  position: fixed;
  margin-top: 64px;
`;

export const Content = styled(AntdLayout.Content)``;

export const Menu = styled(AntdMenu)`
height: 100vh;
  > .ant - menu - item - selected {
  background - color: rgba(79, 93, 117, 0.23)!important;
}
  > .ant - menu - item - selected::after {
  border - right: 3px solid #272e3a!important;
}

  > .ant - menu - submenu - open:hover {
  color: #272e3a!important;
  background - color: #dce0e7!important;
}
  > .ant - menu - submenu - open.ant - menu - submenu - selected {
  color: #272e3a!important;
  background - color: #dce0e7!important;
}

  > .ant - menu - submenu - open > div > .ant - menu - submenu - title:hover {
  color: #272e3a!important;
}
`;

export const SubMenu = styled(AntdMenu.SubMenu)`
color: #272e3a!important;
border - bottom: 1px solid #f0f0f0;
  > div > i {
  color: #272e3a!important;
}

  > div:hover {
  color: #272e3a!important;
}

  > a,
  > .ant - menu - title - content > a {
  color: #272e3a!important;
}
  : hover,
  :active {
  color: #272e3a!important;
  background - color: #dce0e7;
}
  > ul > li {
  padding - left: 28px!important;
}
  > ul > li:after {
  border - right: 3px solid #272e3a!important;
}
  > ul > li.ant - menu - item - selected a,
  > ul > li.ant - menu - item a {
  color: #272e3a!important;
}
`;

export const MenuItem = styled(AntdMenu.Item)`
height: 45px!important;
line - height: 45px!important;
border - bottom: 1px solid #f0f0f0;
  > a,
  > .ant - menu - title - content > a {
  color: #272e3a!important;
}
  : hover,
  :active {
  color: #272e3a!important;
  background - color: #dce0e7;
}
`;
