import { useQuery, gql } from '@apollo/client';

export function usePressureSubQuerys({ yearStart, yearEnd, weekStart, weekEnd }) {
  const variables = { yearStart, yearEnd, weekStart, weekEnd };
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_SUBQUERY, {
    variables,
    skip: !yearStart && !yearEnd && !weekStart && !weekEnd,
  });
  return { loading, data: { Subqueries: data.Subqueries }, error };
}

const GET_SUBQUERY = gql`
  query ResponseGravityRangeSubqueries(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
  ) {
    Subqueries: ResponseGravityRangeSubqueries(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
    ) {
      _id
      groupData {
        device_code
        data_point
        hours
      }
      entry_device_code
      exit_device_code
      data_point
      hours
    }
  }
`;
