import EmptystateImg from '../../assets/images/emptystate.png';
import { NoResultsStyled } from './style';

export const NoResults = (props) => {
  return (
    <>
      <NoResultsStyled>
        <img src={EmptystateImg} alt="empty state" />
        <h2>No se encontraron resultados</h2>
      </NoResultsStyled>
    </>
  );
};
