import { useQuery, gql } from '@apollo/client';

export function GetUsers() {
  const { data = {}, loading, refetch, error } = useQuery(GET_USERSBYCUSTOMER);
  return { loading, data: data.UsersByCustomer || [], refetch, error };
}

const GET_USERSBYCUSTOMER = gql`
  query UsersByCustomer {
    UsersByCustomer {
      _id
      name
      username
      email
      roles
      language
      verified
      active
      type
    }
  }
`;
