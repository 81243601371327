import { useSelector } from 'react-redux';
import { useHistoricLog } from 'apollo/water-preparation';
import HistoricLog from './graph';
import Filters from './filters';
import { Container } from 'components';

const stateName = 'waterQuality.waterPreparantion.historicLog';

export function GraphicLog() {
  const {
    waterQuality: {
      waterPreparantion: { historicLog },
    },
  } = useSelector(({ state }) => state);
  //console.log(historicLog);
  const { data, loading } = useHistoricLog(historicLog);
  return (
    <Container>
      <Filters stateName={stateName} />
      <HistoricLog data={data} loading={loading} />
    </Container>
  );
}
