import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'components';
import { InputRows } from 'components';
import DateRange from '../../components/date-range';
import Subqueries from '../../components/sub-queries';

export default function GraphicLog({ stateName }) {
  const {
    environment: {
      maxandmin: { historicLog },
    },
  } = useSelector(({ state }) => state);
  const { yearStart, yearEnd, weekStart, weekEnd } = historicLog;
  return (
    <Subqueries
      stateName={stateName}
      yearStart={yearStart}
      yearEnd={yearEnd}
      weekEn={weekStart}
      weekStart={weekEnd}
      children
    >
      {({ loading: subLoading, error, data: subqueries }) => {
        return [
          <InputRows key={1}>
            <DateRange stateName={stateName} hideMonth hideDay />
            <Select
              stateName={`${stateName}.block`}
              default={subqueries?.dataPoints[0]}
              data={subqueries?.dataPoints?.map((value) => ({ value }))}
              loading={subLoading}
            />
          </InputRows>,
        ];
      }}
    </Subqueries>
  );
}
