import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useHistoricLog() {
  const {
    waterQuality: {
      filterOrp: {
        graphicLog: { year, week, day },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = {}, loading } = useQuery(GET_OVERVIEW, {
    variables: { year, week, day },
    skip: skip({ year, week, day }),
  });

  return { loading, data: data.HistoricResponseOrpLog || [] };
}

function skip(variables) {
  let skip = true;
  let { year, week, day } = variables;
  skip = ![year, week, day].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportResponseOrpLog($year: Int!, $week: Int!, $day: Int!) {
    HistoricResponseOrpLog: ReportResponseOrpLog(year: $year, week: $week, day: $day) {
      _id
      text
      color
      data {
        _id
        hour
        value
      }
    }
  }
`;
