const customer = (state = { data: {} }, action) => {
  switch (action.type) {
    case 'customer.data':
      return Object.assign({}, state, { data: action.customer });
    case 'customer.reset':
      return Object.assign({}, state, { data: {} });
    default:
      return state;
  }
};

export default customer;
