import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import useComponents from 'hooks/useComponents';
import { userAssing } from 'apollo/configuration';

export function Assing({ onCloseDrawer }) {
  const [isLoading, setLoading] = useState(false);
  const {
    configuration: { user },
  } = useComponents();

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await userAssing({ ...user });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_successfully', true),
      });
      onCloseDrawer();
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...user })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { address } = variables;
  disabled = ![address].every((v) => v);
  return disabled;
}
