import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'components';
import { InputRows } from 'components';
import SingleDates from '../../components/single-date/single-date';
import Subqueries from '../../components/sub-queries/sub-queries';

export function Filters({ stateName }) {
  const {
    waterQuality: {
      filterTankLevel: { register },
    },
  } = useSelector(({ state }) => state);
  const { dataPoints: DataPoints } = useSelector(({ customers }) => customers.data);
  const { year, week } = register;

  return (
    <Subqueries
      key={`subqueries-${stateName}`}
      stateName={stateName}
      yearStart={year}
      yearEnd={year}
      weekEnd={week}
      weekStart={week}
      children
    >
      {({ loading: subLoading, error, data: subqueries }) => {
        let dataPoints = [];
        console.log(subLoading);
        DataPoints.forEach(({ _id, location }) => {
          if (!subqueries?.data_point.includes(_id)) return;
          dataPoints.push({ value: _id, name: location });
        });
        return [
          <InputRows key={`${stateName}-select-inputRows`}>
            <SingleDates key={`${SingleDates}-1`} stateName={stateName} />
            <Select
              key={`select-dataPoint`}
              stateName={`${stateName}.dataPoint`}
              default={subqueries?.data_point[0]}
              data={dataPoints}
              loading={subLoading}
            />
          </InputRows>,
        ];
      }}
    </Subqueries>
  );
}
