import React from 'react';
import PropTypes from 'prop-types';
import './style.less';

export const Container = (props) => {
  const { children, style } = props;
  return (
    <div id={props.id} className="container" style={style}>
      <>{children}</>
    </div>
  );
};

Container.propTypes = {
  style: PropTypes.object,
};
