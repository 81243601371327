import { useQuery, gql } from '@apollo/client';

export function GetUserById(userId) {
  const {
    data = { UserById: {} },
    loading: isLoading,
    error,
  } = useQuery(GET_USERSBYID, { variables: { userId } });
  return { isLoading, data: data.UserById || [], error };
}

export const GET_USERSBYID = gql`
  query UserById($userId: ID!) {
    UserById(userId: $userId) {
      _id
      name
      username
      email
      roles
      language
      verified
      active
      type
    }
  }
`;
