const rolesByAccess = (state = {}, action) => {
  switch (action.type) {
    case 'rolesByAccess.data':
      return Object.assign([], state, [...action.roles]);
    case 'rolesByAccess.reset':
      return Object.assign([], state, {});

    default:
      return state;
  }
};

export default rolesByAccess;
