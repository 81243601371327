import styled from 'styled-components';
import { Form as AntFrom, Alert as AntAlert } from 'antd';

export const ImageBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  align-items: center;
  background: #f2f4f6;
`;

export const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

export const Container = styled.div`
  width: 1221px;
  min-height: 588px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0 auto;
`;

export const Card = styled.div`
  width: 500px;
  background: #ffff;
  border-radius: 4px;
  position: relative;
  padding: 50px 50px;
  border: 1px solid #ffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  button {
    margin-bottom: 10px;
  }
`;

export const Form = styled(AntFrom)`
  width: 100%;
`;

export const SpanTitle = styled.span`
  font-family: Roboto;
  font-size: 30px;
  color: #1f2937;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 40px;
`;

export const ForgotPassword = styled.a`
  color: #0f1c30;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 20px 0px;
  :hover {
    color: #0f1c30;
  }
`;

export const Alert = styled(AntAlert)`
  margin-top: 24px;
  margin-bottom: 0;
`;
