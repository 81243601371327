import React from 'react';
import { componentsRedux } from 'redux/reusable';
import SelectStartDate from '../../components/select-start-date';
import SelectEndDate from '../../components/select-end-date';
import PropTypes from 'prop-types';
import { useResponseDates } from 'apollo/thermometers/getDate';
import { DateData } from 'utils';

const DateRange = (props) => {
  const { yearStart, yearEnd, weekEnd, weekStart, hideWeek, hideDay, hideMonth } = props;
  const { data } = useResponseDates();
  let { Dates } = data || {};
  if (!Dates) return null;
  return (
    <div>
      <SelectStartDate
        {...props}
        {...DateData(Dates, {
          year: yearStart,
          week: weekStart,
          hideWeek,
          hideDay,
          hideMonth,
        })}
      />
      <SelectEndDate
        {...props}
        {...DateData(Dates, {
          year: yearEnd,
          week: weekEnd,
          hideWeek,
          hideDay,
          hideMonth,
        })}
      />
    </div>
  );
};

DateRange.propTypes = {
  stateName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hideWeek: PropTypes.bool,
  hideDay: PropTypes.bool,
  hideMonth: PropTypes.bool,
};

export default componentsRedux(DateRange);
