import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useHistoricLog() {
  const {
    waterQuality: {
      filterTankLevel: {
        graphicLog: { year, week, day, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = { HistoricTankLevelLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { year, week, day },
    skip: !year && !week && !day,
  });
  let dataOverview = [];
  data.HistoricTankLevelLog.forEach((rows) => {
    if (rows.data_point !== dataPoint) return;
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview || [] };
}

const GET_OVERVIEW = gql`
  query ReportResponseTankLevelLog($year: Int!, $week: Int!, $day: Int!) {
    HistoricTankLevelLog: ReportResponseTankLevelLog(year: $year, week: $week, day: $day) {
      _id
      text
      data_point
      color
      data {
        _id
        hour
        value
      }
    }
  }
`;
