import { useEffect } from 'react';
import { Form, Switch } from 'antd';
import PropTypes from 'prop-types';
import { I18n } from 'i18n';

function getValid(value) {
  return !!value;
}

export const Toggle = (props) => {
  let { label, offText, onText, value, disabled, stateName } = props;
  if (label) label = I18n(label, true);
  if (offText) label = I18n(offText, true);
  if (onText && value) label = I18n(onText, true);

  useEffect(() => {
    props.onChange(stateName, props.default, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default]);

  if (!props.isFormItem) {
    return (
      <Switch
        value={props.value}
        disabled={disabled}
        defaultChecked={props.default}
        size={props.size || 'default'}
        onChange={(value) => props.onChange(stateName, value, getValid(value))}
      />
    );
  }

  return (
    <Form.Item
      required={props.required}
      tooltip={props.tooltip}
      labelCol={{ span: 24 }}
      help={props.touched || props.error ? props.error : ''}
      validateStatus={props.touched || props.error ? 'error' : 'success'}
      style={props.formItemStyle}
      label={props.externalLabel ? I18n(props.label, true) : I18n(props.placeholder, true)}
    >
      <Switch
        value={props.value}
        disabled={disabled}
        defaultChecked={props.default}
        size={props.size || 'default'}
        onChange={(value) => props.onChange(stateName, value, getValid(value))}
      />
    </Form.Item>
  );
};

Toggle.propTypes = {
  isFormItem: PropTypes.bool.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
