import { useDispatch, useSelector } from 'react-redux';

const useComponents = (props) => {
  const state = useSelector(({ state }) => state);
  const dispatch = useDispatch();

  // Función que se ejecuta conforme el usuario escribe algo
  const onChange = (key, value, valid) => {
    return dispatch({ type: 'data-' + key, key, value, valid });
  };

  return {
    ...state,
    state: { ...state },
    onChange,
  };
};

export default useComponents;
