import { List, Typography } from 'antd';
import './style.less';
const { Title, Text } = Typography;

export function SelectList(props) {
  return (
    <>
      <List
        loading={props.loading}
        itemLayout="horizontal"
        header={[
          <Title className="rk-list-title">{props.title}</Title>,
          <Text className="rk-list-description">{props.description}</Text>,
        ]}
        dataSource={props.dataSource}
        size={'default'}
        renderItem={(row, i) => (
          <List.Item key={row._id} onClick={() => props.onChecked(row._id)}>
            <List.Item.Meta
              key={`${row._id}-${i}`}
              className={
                props.checked.includes(row._id) ? 'rk-list-checkout' : 'rk-list-no-checkout'
              }
              title={row.email || row.name || row.label}
            />
          </List.Item>
        )}
      />
    </>
  );
}
