import { useHistory } from 'react-router-dom';
import { Spin, Descriptions, Tag } from 'antd';
import { GetUserById } from 'apollo/configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { I18n } from 'i18n';
import { Unassign } from '../components';

export function Detail({ userId, onCloseDrawer }) {
  const history = useHistory();
  const { data: dataUserById, isLoading, error } = GetUserById(userId);

  if (userId && error) history.push('/error-server');

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item
              label={`${I18n('name', true)} ${I18n('and', true)} ${I18n('last_name', true)}`}
            >
              {dataUserById.name}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('email', true)}`}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <p>{dataUserById.email}</p>
                {dataUserById.verified ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                    {`  ${I18n('verified')}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('no_verified')}`}
                  </Tag>
                )}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('name_user', true)}`}>
              {dataUserById.username}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('state', true)}`}>
              {dataUserById.active ? (
                <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                  {`  ${I18n('activate')}`}
                </Tag>
              ) : (
                <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                  {`  ${I18n('inactive')}`}
                </Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('permissions', true)}`}>
              {dataUserById.roles.map((r) => I18n(r, true)).join(', ')}
            </Descriptions.Item>
          </Descriptions>
          <div className="rk-button-group">
            <Unassign userId={userId} onCloseDrawer={() => onCloseDrawer()} />
          </div>
        </>
      )}
    </>
  );
}
