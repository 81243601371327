import { useQuery, gql } from '@apollo/client';

export function useLog(variables) {
  const { data = {}, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });
  return { loading: loading || true, data: data.DataPointLog || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ResponseByDataPointLog($yearStart: Int!, $yearEnd: Int!, $weekStart: Int!, $weekEnd: Int!) {
    DataPointLog: ReportDataPointsLog(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
    ) {
      _id
      date
      year
      week
      day
      name
      user
      quantityWater
      quantityDioxoro
      valueORP
      hours
    }
  }
`;
