import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

function skip(variables) {
  let skip = true;
  let { year, week, day } = variables;
  skip = ![year && week && day].every((v) => v);
  return skip;
}

export function useHistoricLogMultiple() {
  const {
    waterQuality: {
      filterPressure: {
        graphicLogMultiple: { year, week, day, dataPoints },
      },
    },
  } = useSelector(({ state }) => state);
  //console.log( year, week, day, dataPoints );
  const { data = { HistoricPressureLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { year, week, day },
    skip: skip({ year, week, day }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  let dataOverview = [];
  data.HistoricPressureLog.forEach((rows) => {
    if (!dataPoints.includes(rows.data_point)) return;
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview || [] };
}

const GET_OVERVIEW = gql`
  query ReportResponsePressureLog($year: Int!, $week: Int!, $day: Int!) {
    HistoricPressureLog: ReportResponsePressureLog(year: $year, week: $week, day: $day) {
      _id
      text
      data_point
      color
      data {
        _id
        hour
        value
      }
    }
  }
`;
