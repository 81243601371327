import React from 'react';
import { Line } from 'components';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { weekArray } from 'utils';

export default function GraphicLog(props) {
  const { data, loading } = props;
  const threshold = useSelector(
    ({
      customers: {
        data: {
          thresholds: { orp },
        },
      },
    }) => orp,
  );
  return (
    <Line
      loading={loading}
      data={data}
      thresholds={threshold}
      xAccessor="day"
      yAccessor="value"
      colors={{ datum: 'color' }}
      axes={{
        left: {
          tickSize: 4,
          tickPadding: 5,
          tickRotation: 0,
          legend: I18n('ORP (mv)'),
          legendOffset: -40,
          legendPosition: 'middle',
        },
        bottom: {
          tickSize: 10,
          tickValues: 12,
          tickRotation: 0,
          tickFormat: weekArray(),
          legend: I18n('days', true),
          legendOffset: 55,
          legendPosition: 'middle',
        },
      }}
    />
  );
}
