import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import useComponents from 'hooks/useComponents';
import { DataPointsConfigure } from 'apollo/configuration';
import { ModulesTemetry } from 'utils';

export function Save({ codeId, onCloseDrawer }) {
  const [isLoading, setLoading] = useState(false);
  const {
    configuration: { dataPoints },
  } = useComponents();

  const handleClick = async () => {
    setLoading(true);
    const { data, error, message } = await DataPointsConfigure({ ...dataPoints, codeId });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      onCloseDrawer();
      Toast.success({
        key: 'success-save-data-points',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...dataPoints, codeId })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { name, longitude, latitude, codeId, location, module, time, diameter, } = variables;
  if (codeId) disabled = ![name, longitude, latitude, module, location].every((v) => v);
  else if (ModulesTemetry.includes(module))
    disabled = ![name, longitude, latitude, module, location, time].every((v) => v);
  else if (['filter_tank_levels'].includes(module))
    disabled = ![name, longitude, latitude, module, location, time, diameter].every((v) => v);
  else disabled = ![name, longitude, latitude, module].every((v) => v);
  return disabled;
}
