import React from 'react';
import { DatePicker as AndtDatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import { I18n } from 'i18n';

import { componentsRedux } from '../../redux/reusable';

export const RangePicker = (props) => {
  let { value, style, disabled, stateName } = props;

  if (!props.isFormItem) {
    return (
      <AndtDatePicker.RangePicker
        disabled={disabled}
        style={style}
        value={value}
        onChange={(range) => props.onChange(stateName, range)}
      />
    );
  }
  return (
    <Form.Item
      required={props.required}
      tooltip={props.tooltip}
      labelCol={{ span: 24 }}
      help={props.touched || props.error ? props.error : ''}
      validateStatus={props.touched || props.error ? 'error' : 'success'}
      style={props.formItemStyle}
      label={props.externalLabel ? I18n(props.label, true) : I18n(props.placeholder, true)}
    >
      <AndtDatePicker.RangePicker
        disabled={disabled}
        style={style}
        value={value}
        onChange={(range) => props.onChange(stateName, range)}
      />
    </Form.Item>
  );
};

RangePicker.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default componentsRedux(RangePicker);
