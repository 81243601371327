import React from 'react';
import { Container } from 'components';
import { useHGravityFiltersLog } from 'apollo/gravity-filter';
import HistoricLog from './graph';
import { Filters } from './filters';

const stateName = 'waterQuality.gravityFilters.graphicLog';

export function GraphicGravityFilters() {
  const { data, loading } = useHGravityFiltersLog();

  return (
    <Container>
      <Filters stateName={stateName} />
      <HistoricLog data={data} loading={loading} />
    </Container>
  );
}
