const roles = (state = {}, action) => {
  switch (action.type) {
    case 'roles.data':
      return Object.assign([], state, [...action.roles]);
    case 'roles.reset':
      return Object.assign([], state, {});
    default:
      return state;
  }
};

export default roles;
