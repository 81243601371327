import PropTypes from 'prop-types';
import './style.less';

export const Content = ({ children }) => {
  return (
    <>
      <div className="content-form">
        <>{children}</>
      </div>
    </>
  );
};

Content.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
};
