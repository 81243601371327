import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useHistoricLog() {
  const {
    environment: {
      maxandmin: { historicLog },
    },
  } = useSelector(({ state }) => state);
  const { yearStart, yearEnd, weekStart, weekEnd, block } = historicLog;
  const variables = { yearStart, yearEnd, weekStart, weekEnd, dataPoint: block };
  const { data = {}, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });
  return { loading, data: data.HistoricTemeperatureLog || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query HistoricTemeperatureLog(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dataPoint: String!
  ) {
    HistoricTemeperatureLog: HistoricTemeperatureLog(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dataPoint: $dataPoint
    ) {
      _id
      text
      color
      data {
        _id
        day
        value
      }
    }
  }
`;
