import React from 'react';
import { Line } from 'components';
import { I18n } from 'i18n';
import { hoursByDay } from 'utils';

export default function GraphicGravityFiltersLog(props) {
  const { data, loading, threshold, yValuesMax } = props;
  return (
    <Line
      loading={loading}
      data={data}
      title={I18n('temperature', true)}
      thresholds={threshold}
      xAccessor="hour"
      yAccessor="value"
      yValuesMax={yValuesMax}
      axes={{
        left: {
          tickSize: 4,
          tickPadding: 5,
          tickRotation: 0,
          legend: `${I18n('temperature', true)} [grados centígrados]`,
          legendOffset: -40,
          legendPosition: 'middle',
        },
        bottom: {
          tickValues: hoursByDay(60),
          tickSize: 10,
          tickRotation: 90,
          legend: I18n('hour', true),
          legendOffset: 55,
          legendPosition: 'middle',
        },
      }}
    />
  );
}
