import { useQuery, gql } from '@apollo/client';

export function GetDeviceById(codeId) {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_DEBVICESBYID, {
    variables: { DeviceById: codeId },
    skip: skip({ DeviceById: codeId }),
  });
  return { isLoading: loading, data: data.DeviceById || [], error };
}

function skip({ DeviceById }) {
  let skip = true;
  skip = ![DeviceById].every((v) => v);
  return skip;
}

const GET_DEBVICESBYID = gql`
  query DeviceById($DeviceById: ID!) {
    DeviceById(DeviceById: $DeviceById) {
      _id
      name
      code
      type
      customerId
      location
      color
      data_point {
        _id
        name
      }
      active
    }
  }
`;
