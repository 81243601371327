import { I18n } from 'i18n';
import { Tabs, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Drawer } from 'components';
import { ChangePassword, DetailProfile } from '../components';
import './style.less';

export function Profile() {
  const dispatch = useDispatch();

  const { profile } = useSelector(({ app }) => app);
  const user = useSelector(({ user }) => user.data);

  const onCloseDrawer = () => {
    dispatch({ type: 'app.profile', profile: false });
  };

  return (
    <Drawer
      key="drawer-profile"
      showTitle={true}
      title={I18n('profile')}
      placement="right"
      onClose={(e) => onCloseDrawer(e)}
      visible={profile}
      closeIcon={[
        <div key="close-profile" style={{ display: 'flex', gap: 30 }}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>,
      ]}
    >
      <>
        <div className="rk-container-profile">
          <div className="rk-avatar-profile">
            <span>{user.name[0]}</span>
          </div>
          <Descriptions key="detail-profile" size="middle" column={1}>
            <Descriptions.Item label={`${I18n('name', true)}`}>{user.name}</Descriptions.Item>
          </Descriptions>
        </div>

        <Tabs defaultActiveKey="profile">
          <Tabs.TabPane tab={I18n('profile')} key="profile">
            <DetailProfile key="detail-profile" />
          </Tabs.TabPane>
          <Tabs.TabPane tab={I18n('change_password')} key="change_password">
            <ChangePassword key="change-password" />
          </Tabs.TabPane>
        </Tabs>
      </>
    </Drawer>
  );
}
