import React from 'react';
import { I18n } from 'i18n';
import { Spin } from 'antd';
import { Container, Content } from 'components';
import { Table as TableUI } from 'antd';
import { Filters } from './filters';
import { useOverview } from 'apollo/filter-pressure';

const stateName = 'waterQuality.filterPressure.register';

export function Log() {
  const { data, loading } = useOverview();
  return (
    <Container>
      <Filters stateName={stateName} />
      <Content>
        {loading && !data.length ? (
          <Spin spinning={loading} />
        ) : (
          <TableUI
            dataSource={data}
            bordered
            size="small"
            pagination={false}
            rowKey={(data) => data._id + data.day + data.hours}
            columns={columns(data)}
          />
        )}
      </Content>
    </Container>
  );
}

function columns(data = []) {
  let columns = [];
  columns.push({
    title: I18n('hour', true),
    key: 'hours_apo',
    dataIndex: 'hours_apo',
    filters: findFilters(data, 'hours_apo'),
    onFilter: (value, record) => record['hours_ori'] === value,
  });
  ['entry', 'exit'].forEach((key) =>
    columns.push({
      title: I18n(key, true),
      dataIndex: `data_${key}`,
      key: `data_${key}`,
      filters: findFilters(data, `data_${key}`),
      onFilter: (value, record) => record[`data_${key}`] === value,
    }),
  );
  //difference
  columns.push({
    title: I18n('difference', true),
    key: 'difference',
    dataIndex: 'difference',
    filters: findFilters(data, 'difference'),
    onFilter: (value, record) => record['difference'] === value,
  });

  return columns;
}

function findFilters(data, key) {
  let out = data.map((item) => item[key]);
  out = [...new Set(out)].sort();
  return out.map((value) => ({ text: value, value }));
}
