import React from 'react';
import { useLocation } from 'react-router-dom';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Sider, Main } from '../components';
import { Log } from '../../filter-flowrates/views/log';
import { HistoricLog } from '../../filter-flowrates/views/historic-log';

const ROUTES = [
  {
    key: 'daily-graph',
    name: I18n('daily_graph', true),
    path: '/water-quality/filter-flowrates/daily-graph',
    component: HistoricLog,
  },
  {
    key: 'table',
    name: I18n('data_table', true),
    path: '/water-quality/filter-flowrates/table',
    component: Log,
  },
];

export function FlowratesFilter() {
  const { collapseNav } = useSelector(({ app }) => app);
  const { pathname } = useLocation();
  const location = pathname.split('/');
  return (
    <Layout>
      {!collapseNav && (
        <Sider
          ROUTES={ROUTES}
          defaultSelectedKeys={location[3]}
          title={I18n('filter_flowrates', true)}
          key={'filter_flowrates'}
        />
      )}
      <Main ROUTES={ROUTES} collapseNav={collapseNav} breadcrumb={location} />
    </Layout>
  );
}
