import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'components';
import { InputRows } from 'components';
import DateRange from '../../components/date-range';
import Subqueries from '../../components/sub-queries';

export default function Filters({ stateName }) {
  const {
    environment: {
      maxandmin: { log },
    },
  } = useSelector(({ state }) => state);
  const { yearStart, yearEnd, weekStart, weekEnd } = log;
  return (
    <Subqueries
      stateName={stateName}
      yearStart={yearStart}
      yearEnd={yearEnd}
      weekEn={weekStart}
      weekStart={weekEnd}
      children
    >
      {({ loading: subLoading, data: subqueries }) => {
        return [
          <InputRows key={1}>
            <DateRange stateName={stateName} hideMonth hideDay />
            <Select
              stateName={`${stateName}.block`}
              default={subqueries?.dataPoints[0]}
              data={subqueries?.dataPoints?.map((value) => ({ value }))}
              loading={subLoading}
            />
            <Select
              stateName={`${stateName}.device`}
              default={subqueries?.devices[0]}
              data={subqueries?.devices?.map((value) => ({ value }))}
              loading={subLoading}
            />
          </InputRows>,
        ];
      }}
    </Subqueries>
  );
}
