import React from 'react';
import PropTypes from 'prop-types';
import { SwishSpinner } from 'react-spinners-kit';
import './style.less';

export const Loading = (props) => {
  let { loading } = props;

  return (
    <div className="container-loading">
      <SwishSpinner size={60} color="#4f5d75" loading={loading} />
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
};
