import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import { I18n } from 'i18n';

function getValid(value) {
  return !!value;
}

export const Text = (props) => {
  let {
    disabled,
    externalLabel,
    noFormItem,
    placeholder,
    name,
    className,
    size,
    style,
    value,
    stateName,
    touched,
    error,
    required,
    tooltip,
    formItemStyle,
  } = props;

  useEffect(() => {
    props.onChange(stateName, props.default, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default]);

  if (noFormItem) {
    return (
      <Input
        required={required}
        disabled={disabled}
        type="text"
        value={value}
        placeholder={placeholder}
        name={name}
        size={size || 'middle'}
        defaultValue={props.default}
        className={className}
        onChange={(e) => props.onChange(stateName, e.target.value, getValid(e.target.value))}
      />
    );
  }

  return (
    <Form.Item
      required={required}
      tooltip={tooltip}
      labelCol={{ span: 24 }}
      help={touched || error ? error : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      style={formItemStyle}
      label={externalLabel ? I18n(placeholder, true) : false}
    >
      <Input
        disabled={disabled}
        style={{ ...style }}
        type="text"
        value={value}
        size={size || 'middle'}
        placeholder={I18n(placeholder, true)}
        defaultValue={props.default}
        className={className}
        onChange={(e) => props.onChange(stateName, e.target.value, getValid(e.target.value))}
      />
    </Form.Item>
  );
};

Text.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rules: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
