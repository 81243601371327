import React from 'react';
import { Container } from 'components';
import { useHistoricLog } from 'apollo/thermometers/getHistoricLog';
import Filters from './filters';
import HistoricLog from './graph';

const stateName = 'environment.maxandmin.historicLog';

export function GraphicLog() {
  const { data, loading } = useHistoricLog();
  return (
    <Container>
      <Filters stateName={stateName} />
      <HistoricLog data={data} loading={loading} />
    </Container>
  );
}
