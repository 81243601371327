import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'i18n';
import { Select } from 'components/select';

export default function SelectEndDate({
  years,
  months,
  weeks,
  days,
  hideWeek,
  hideDay,
  hideMonth,
  stateName,
}) {
  return (
    <>
      <Select
        label={I18n('year')}
        stateName={`${stateName}.yearEnd`}
        data={years}
        placeholder={I18n('year')}
        default={years[0]?.value}
        style={{ marginRight: 5 }}
      />
      {!hideMonth ? (
        <Select
          label={I18n('month')}
          stateName={`${stateName}.monthEnd`}
          data={months}
          placeholder={I18n('month')}
          default={months[0]?.value}
          style={{ marginRight: 5 }}
        />
      ) : null}
      {!hideWeek ? (
        <Select
          label={I18n('week')}
          stateName={`${stateName}.weekEnd`}
          data={weeks}
          placeholder={I18n('week')}
          default={weeks[0]?.value}
          style={{ marginRight: 5 }}
        />
      ) : null}
      {!hideDay ? (
        <Select
          label={I18n('day')}
          stateName={`${stateName}.dayEnd`}
          data={days}
          placeholder={I18n('day')}
          default={days[0]?.value}
          style={{ width: 100 }}
        />
      ) : null}
    </>
  );
}

SelectEndDate.propTypes = {
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
  days: PropTypes.array,
  stateName: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hideWeek: PropTypes.bool,
  hideDay: PropTypes.bool,
  hideMonth: PropTypes.bool,
};
