import { useQuery, gql } from '@apollo/client';

export function useHistoricLog(variables) {
  // const {
  //   waterQuality: {
  //     waterPreparantion: { historicLog },
  //   },
  // } = useSelector(({ state }) => state);
  // const { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = historicLog;
  //const variables = { yearStart, yearEnd, weekStart, weekEnd, dataPoint };

  const { data = {}, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });
  return { loading, data: data.HistoricDatapointsLog || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query HistoricDatapointsLog(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dataPoint: String!
  ) {
    HistoricDatapointsLog: HistoricDatapointsLog(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dataPoint: $dataPoint
    ) {
      _id
      text
      color
      data {
        _id
        day
        value
      }
    }
  }
`;
