import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ReportContainer } from 'components/report-container';
import { Spin } from 'antd';
import { I18n } from 'i18n';
import { findByYValueMax, rawDataReducer, RefsLayer, commonProperties, Title } from './helper';
import PropTypes from 'prop-types';

export function Line(props) {
  const {
    loading,
    data,
    thresholds = [],
    title = '',
    typeLine,
    axes,
    xAccessor,
    yAccessor,
    legendTitle,
    yValuesMax
  } = props;
  const { rawData, yValues } = rawDataReducer(data, xAccessor, yAccessor, axes.bottom.tickFormat);
  const yScaleMax = findByYValueMax(thresholds, yValues, yValuesMax);

  if (loading && data.length === 0)
    return (
      <ReportContainer>
        <Spin className="rk-flex-center" spinning={true} />
      </ReportContainer>
    );

  return (
    <ReportContainer>
      {data.length !== 0 && (
        <>
          <ResponsiveLine
            title={title}
            {...commonProperties}
            data={rawData}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              stacked: false,
              min: 0,
              max: yScaleMax,
            }}
            thresholds={thresholds}
            yValuesMax={yScaleMax}
            axisBottom={axes.bottom}
            axisLeft={axes.left}
            layers={[
              'grid',
              RefsLayer,
              'markers',
              'axes',
              'areas',
              'crosshair',
              'lines',
              'points',
              'slices',
              'mesh',
              'legends',
              Title,
            ]}
            motionDamping={50}
            curve={typeLine}
            enablePointLabel={false}
            colors={({ color }) => color}
            pointSize={4}
            lineWidth={2}
            pointBorderColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointLabelYOffset={-12}
            enableSlices="x"
            useMesh={true}
            theme={{
              axis: { ticks: { text: { fontSize: 12 } } },
              grid: { line: { stroke: '#f5f5f5' } },
            }}
          />
          <div className="legend">
            <div>{legendTitle}</div>
            <hr />
            <div className="body" style={{ overflow: data.length > 15 ? 'scroll' : 'auto' }}>
              {data.map(({ color, text = '' }) => {
                return (
                  <div key={text} style={{ display: 'flex', marginBottom: 5 }}>
                    <div className="box" style={{ backgroundColor: color }} />
                    <div className="text">{I18n(text, true)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </ReportContainer>
  );
}

Line.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  thresholds: PropTypes.object,
  colors: PropTypes.object,
  title: PropTypes.string,
  typeLine: PropTypes.string,
  axes: PropTypes.object,
  yValuesMax: PropTypes.number, 
};

Line.defaultProps = {
  data: [],
  thresholds: {},
  axes: {},
  colors: { scheme: 'category10' },
};
