import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import useComponents from 'hooks/useComponents';
import { DeviceConfigure } from 'apollo/configuration';

export function Save({ _id, code }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const {
    state: {
      configuration: { devices },
    },
  } = useComponents();

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await DeviceConfigure({ ...devices, _id });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      history.push('/configuration/devices');
      Toast.success({
        key: 'success-save-data-points',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        loading={isLoading}
        className="rk-button-primary"
        onClick={handleClick}
        disabled={isDisabled({ ...devices, code, _id })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { _id, name, type, color, active, data_point } = variables;
  if (_id) disabled = ![_id, name, type, color, active, data_point].every((v) => v);
  else disabled = ![name, type, color, active, data_point].every((v) => v);
  return disabled;
}
