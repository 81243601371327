import React from 'react';
import { componentsRedux } from 'redux/reusable';
import { usePressureSubQuerys } from 'apollo/filter-pressure';

const Subqueries = (props) => {
  const { yearStart, yearEnd, weekStart, weekEnd } = props;
  const { data, loading } = usePressureSubQuerys({
    yearStart,
    yearEnd,
    weekStart,
    weekEnd,
  });
  return <>{props.children({ loading, data: data.Subqueries })}</>;
};

export default componentsRedux(Subqueries);
