import { Table as TableUI, Form} from 'antd';
import { useHistory} from 'react-router-dom';
import { Text, Number, Spin } from 'components';
import { Select, FormCard } from 'components';
import { I18n } from 'i18n';
import { useSelector } from 'react-redux';
import useComponents from 'hooks/useComponents';
import { GetDataPointsById } from 'apollo/configuration';
import { Save } from '../components/data-point-create/save';
import { Transmision, ModulesTemetry } from 'utils';
import { thresholdsColors } from 'utils';
const { Column } = TableUI;
const stateName = 'configuration.dataPoints';

export function DataPointForm(props) {
  const { codeId, onCloseDrawer } = props;
  const history = useHistory();
  const { modules } = useSelector(({ customers }) => customers.data);
  const selectModule = modules.map(({ name }) => ({ value: name, name: I18n(name, true) }));
  const valuesTransmision = Transmision.map((key) => ({ value: key, name: key }));
  const {
    onChange,
    configuration: { dataPoints },
  } = useComponents();

  const { data: dataPointsById, loading, error } = GetDataPointsById({ codeId });
  const tableData = Object.values(dataPointsById.threshold || []) || [];
  const colors = Object.keys(thresholdsColors).map((key) => ({
    value: key,
    name: I18n(key, true),
  }));

  if (codeId && error) history.push('/error-server');

  return (
    <>
      {!loading ? (
        <Form layout="vertical">
          <FormCard
            title={I18n('basic_data_points_information')}
            description={I18n('configure_all_general_data_points_information')}
          >
            <Text
              value={dataPoints.name}
              default={dataPointsById.name}
              onChange={onChange}
              stateName={`${stateName}.name`}
              placeholder={`${I18n('name', true)}:*`}
              externalLabel
            />
            <Number
              defaultValue={dataPointsById.longitude}
              onChange={onChange}
              step={0.000001}
              stateName={`${stateName}.longitude`}
              placeholder={`${I18n('longitude', true)}:*`}
              externalLabel
            />
            <Number
              defaultValue={dataPointsById.latitude}
              onChange={onChange}
              stateName={`${stateName}.latitude`}
              placeholder={`${I18n('latitude', true)}:*`}
              externalLabel
              step={0.000001}
            />
          </FormCard>
          <FormCard
            title={I18n('module', true)}
            description={I18n('select_a_module_for_the_data_point')}
          >
            <Form.Item>
              <Select
                default={dataPointsById.module || selectModule[0].value}
                label={`${I18n('modules', true)}:*`}
                stateName={`${stateName}.module`}
                data={selectModule}
                placeholder={I18n('modules', true)}
              />
            </Form.Item>
            {ModulesTemetry.includes(dataPoints.module) && (
              <Form.Item labelCol={{ span: 24 }} label={`${I18n('transmision', true)}:*`}>
                <Select
                  externalLabel
                  default={dataPointsById.time}
                  label={`${I18n('transmision', true)}:*`}
                  stateName={`${stateName}.time`}
                  data={valuesTransmision}
                  placeholder={I18n('transmision', true)}
                />
              </Form.Item>
            )}
            {['filter_tank_levels'].includes(dataPoints.module) && (
              <Form.Item labelCol={{ span: 24 }} label={`${I18n('diameter', true)}:*`}>
                <Number
                  defaultValue={dataPointsById.diameter}
                  onChange={onChange}
                  stateName={`${stateName}.diameter`}
                  placeholder={`${I18n('diameter', true)}:*`}
                  step={0.01}
                />
              </Form.Item>
            )}
          </FormCard>
          <FormCard
            title={I18n('location', true)}
            description={I18n('configuration_where_the_location_of_the_data_point')}
          >
            <Text
              value={dataPoints.location}
              default={dataPointsById.location}
              onChange={onChange}
              stateName={`${stateName}.location`}
              placeholder={`${I18n('location', true)}`}
              externalLabel={false}
            />
          </FormCard>
          <FormCard
          title={I18n('thresholds',true)}
          description={I18n('nothing to describe',true)}>
            <TableUI
          dataSource={tableData}
          loading={loading}
          pagination={false}
          bordered
          rowKey={(_data, i) => i}
        >
          {['min','max'].map((field, i) => (
            <Column title={I18n(field, true)} dataIndex={field} key={field} 
            render={(key, _record, index) =>(
              <Number
              defaultValue={key}
              onChange={onChange}
              stateName={`${stateName}.threshold.${index}.${field}`}
              placeholder={`${field}`}
              key={key}
            />
            )}/>
          ))}
          <Column
            width={80}
            title={I18n('color', true)}
            dataIndex={'color'}
            key={'color'}
            render={(color, _record, index) => (
              <Form.Item>
                <Select
              default={color}
              value={color}
              setInput={onChange}
              data={colors}
              stateName={`${stateName}.threshold.${index}.color`}
            />
              </Form.Item>
            )}
          />
        </TableUI>
          </FormCard>
          {['temperature_and_humidity'].includes(dataPointsById.module) && (
              <FormCard
              title={I18n('maximum_value_on_the_axisy', true)}
              description={I18n('setting_the_maximum_value_for_the_graph_axis')}
              >
                <Number
                  value={dataPointsById.yValuesMax}
                  default={dataPointsById.yValuesMax === 0 && ""}
                  onChange={onChange}
                  stateName={`${stateName}.yValuesMax`}
                  placeholder={`${I18n('maximum_value_on_the_axisy', true)}`}
                  externalLabel={false}
                />
              </FormCard>
            )}
        </Form>
      ) : (
        <Spin spinning={loading} />
      )}
      <div className="rk-button-group">
        <Save codeId={codeId} onCloseDrawer={onCloseDrawer} />
        {/* <State
              userId={userId}
              active={dataUserById.active}
              onCloseDrawer={() => onCloseDrawer()}
            /> */}
      </div>
    </>
  );
}
