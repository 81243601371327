import styled from 'styled-components';
import { List as AntdList, Button as AntdButton, Select as AntSelect } from 'antd';

export const List = styled(AntdList)`
  margin: 5vh 25vh !important;
  color: #7e8ea8 !important;
  border-radius: 5px !important;
  border: 1px solid #d9d9d9 !important;
  background: #fff;
  @media only screen and (max-width: 736px) {
    margin: 0.5vh 0.5vh !important;
  }
`;

export const ListItem = styled(AntdList.Item)`
  font-size: 20px !important;
  :hover {
    color: #272e3a !important;
    cursor: pointer !important;
    background-color: #f3f5f7 !important;
  }
  > p {
    color: rgba(0, 0, 0, 0.45) !important;
    font-size: 14px !important;
    line-height: 1.5715 !important;
  }
`;

export const ListItemMeta = styled(AntdList.Item.Meta)`
  .ant-list-item-meta-content {
    > h4.ant-list-item-meta-title {
      align-items: center;
      justify-content: space-between;
      color: #2a2a33;
      font-size: 1em !important;
      line-height: 1.5715 !important;
      font-weight: 400;
    }
    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.45) !important;
      font-size: 14px !important;
      line-height: 1.5715 !important;
    }
  }
`;

export const Button = styled(AntdButton)`
  float: right !important;
  :hover {
    color: #7e8ea8 !important;
    border-color: #7e8ea8 !important;
  }
`;

export const ContainerSelect = styled.div`
  float: right !important;
  margin-right: 1rem;
  display: flex;
  gap: 0.3rem;
  > svg {
    margin-top: 0.3rem;
  }
`;

export const Select = styled(AntSelect)`
  :hover {
    color: #7e8ea8 !important;
    border-color: #7e8ea8 !important;
  }
`;
