import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

const GET_CUSTOMERS = gql`
  query CustomersByUser {
    CustomersByUser {
      _id
      name
      active
      state
      type
    }
  }
`;

export async function getCustomers() {
  return await client.query({ query: GET_CUSTOMERS });
}
