import { I18n } from 'i18n';
import { Button, Alert } from 'antd';
import { changePassword } from 'apollo/account';
import { Toast } from 'components';
import { useMe } from 'hooks/useMe';

export function ChangePassword() {
  const { signOut } = useMe();

  const onSubmit = async () => {
    const { data, error, message } = await changePassword();
    if (error) Toast.error({ key: 'error-set-password', message: I18n(message, true) });
    if (data) {
      signOut();
      Toast.success({ key: 'success-set-password', message: I18n(message, true) });
    }
  };

  return (
    <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
      <Alert message={I18n('alert_notification_reset_pasword')} type="info" showIcon />
      <Button block={true} onClick={() => onSubmit()} style={{ height: 46 }} type="primary">
        {`${I18n('change_password')}`}
      </Button>
    </div>
  );
}
