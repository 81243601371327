import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { I18n } from 'i18n';
import { ContentNotFound, InternalServerError, H2 } from './style';

export function PageErro500() {
  return (
    <ContentNotFound>
      <InternalServerError />
      <H2>{I18n('internal_server_error', true)}</H2>
      <Link to="/">
        <Button type="primary">{I18n('return_home', true)}</Button>
      </Link>
    </ContentNotFound>
  );
}
