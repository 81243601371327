import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function getCurrentMe() {
  return await client.query({ query: GET_CURRENTME });
}

const GET_CURRENTME = gql`
  query Current {
    ProfileUser {
      _id
      name
      username
      email
      roles
      language
      verified
      active
      type
    }
    CurrentCustomer {
      _id
      name
      createdAt
      weekStart
      localidad
      geolocation
      modules {
        name
        color
        active
        type
        application
      }
      hoursTime
      thresholds
      users
      active
      dataPoints {
        _id
        name
        customerId
        longitude
        latitude
        active
        module
        location
        time
        diameter
        setting
      }
      devices {
        _id
        name
        code
        type
        location
        color
        data_point
        active
      }
    }
  }
`;
