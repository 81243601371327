import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function userAssing(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_USERS,
        variables: { ...variables },
        skip: skip(variables),
        refetchQueries: ['UsersByCustomer'],
      })
      .then(
        ({
          data: {
            UserAssing: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { address } = variables;
  skip = ![address].every((v) => v);
  return skip;
}

const CONFIGURE_USERS = gql`
  mutation UserAssing($address: String!) {
    UserAssing(address: $address) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;
