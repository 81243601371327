import { useEffect, useState } from 'react';
import { getAppParams } from 'apollo/account';
import { useHistory } from 'react-router-dom';
import { I18n } from 'i18n';
import { useDispatch } from 'react-redux';

export function useParams() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState('');
  useEffect(() => {
    const fetchQuery = async () => {
      try {
        setLoading(true);
        const {
          data: { Applications = [], Roles = [], RoleByAccess = [] },
        } = await getAppParams();
        dispatch({ type: 'application.data', Applications: Applications });
        dispatch({
          type: 'roles.data',
          roles: Roles.map((key) => ({
            value: key.name,
            name: I18n(key.name, true),
            access: key.access,
          })),
        });
        dispatch({
          type: 'rolesByAccess.data',
          roles: RoleByAccess.map((key) => key.name),
        });
        setLoading(false);
      } catch (error) {
        setError(new Error(error.message));
      }
    };
    fetchQuery();
  }, [dispatch, history]);
  return { isLoading, isError };
}
