import { I18n } from 'i18n';
import moment from 'moment';

export function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const redex = {
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  coordinates:
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
  number: /^\d+$/,
};

export function getLanguage({ language }) {
  moment.locale(language || 'es');
  return language || 'es';
}

export const hasPermissionUser = (roles, RoleByAccess, role) => {
  const accessByRole = RoleByAccess.filter((key) => roles.includes(key));
  let permission = true;
  if (accessByRole.length && accessByRole.includes('configuration')) permission = false;
  if (accessByRole.length && accessByRole.includes('full_access')) permission = false;
  return permission;
};

export const hasPermission = (modules, module) => {
  let data = modules.filter(({ name }) => name === module);
  if (!data?.length) return true;
  return false;
};

export const thresholdsColors = {
  green: 'rgba(0, 128, 0, .35)',
  yellow: 'rgba(255, 165, 0, .35)',
  red: 'rgba(	255, 0, 0, .35)',
};

export const days = {
  1: I18n('monday', true),
  2: I18n('tuesday', true),
  3: I18n('wednesday', true),
  4: I18n('thursday', true),
  5: I18n('friday', true),
  6: I18n('saturday', true),
  7: I18n('sunday', true),
};

export const Transmision = [5, 10, 15, 20];

export const ModulesTemetry = [
  'filter_pressure',
  'filter_orp',
  'temperature_and_humidity',
  'gravity_filter',
  'filter_flowrates',
  'filter_tank_levels',
];

export const hoursByDay = (time) => {
  let hours = [];
  for (let i = 0; i <= 24; i++) {
    let count = 0;
    while (count < 60 && i !== 24) {
      let hour = `${i < 10 ? 0 : ''}${i}:${count === 0 ? 0 : ''}${count}`;
      hours.push(hour);
      count = count + time;
    }
    if (i === 24) {
      let hour = `24:00`;
      hours.push(hour);
    }
  }
  return hours;
};

export function generateUUID(customerId) {
  let d = new Date().getTime();

  const uuid = 'xxxx-5xxx-yxxx-xxxxxx'.replace(/[xy]/g, (c) => {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return `${customerId}-${uuid}`;
}
