import React from 'react';
import { useSelector } from 'react-redux';
import { SelectMultiple } from 'components';
import { InputRows } from 'components';
import { I18n } from 'i18n';
import SingleDates from '../../components/single-date/single-date';
import Subqueries from '../../components/sub-queries/sub-queries';

export function Filters({ stateName }) {
  const {
    waterQuality: {
      filterPressure: { graphicLogMultiple },
    },
  } = useSelector(({ state }) => state);
  const { dataPoints: DataPoints } = useSelector(({ customers }) => customers.data);
  const { year, week } = graphicLogMultiple;
  return (
    <Subqueries
      stateName={stateName}
      yearStart={year}
      yearEnd={year}
      weekEnd={week}
      weekStart={week}
      children
    >
      {({ loading: subLoading, error, data: subqueries = { data_point: [] } }) => {
        let dataPoints = [];
        DataPoints.forEach(({ _id, location }) => {
          if (!subqueries.data_point.includes(_id)) return;
          dataPoints.push({ value: _id, name: location, label: I18n(location, true) });
        });
        return [
          <InputRows key={`module-pressure/select-multiple/input-rows`}>
            <SingleDates stateName={stateName} />
            <SelectMultiple
              key={`module-pressure/select-multiple/input-rows/select-multiple`}
              stateName={`${stateName}.dataPoints`}
              default={subqueries.data_point}
              data={dataPoints}
              loading={subLoading}
              style={{ width: 250 }}
              isFormItem={false}
            />
          </InputRows>,
        ];
      }}
    </Subqueries>
  );
}
