import React, { useState } from 'react';
import { I18n } from 'i18n';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { Email, Loading, Toast } from 'components';
import { resetPassword } from 'apollo/account';
import useComponents from 'hooks/useComponents';
import { Container, Card, Form, SpanTitle, ImageBackground, ForgotPassword } from './style';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const { resetPassword: stateResetPassword, onChange } = useComponents();
  const { app } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const { data, loading, error, message } = await resetPassword(stateResetPassword.email);
    if (error) Toast.error({ key: 'error-reset-password', message: I18n(message, true) });
    if (data) Toast.success({ key: 'success-reset-password', message: I18n(message, true) });
    setLoading(loading);
  };

  const isDisabled = () => {
    return stateResetPassword['email-valid'] ? false : true;
  };

  if (loading) return <Loading loading={loading} />;

  return (
    <ImageBackground>
      <Container>
        <Card>
          <Form layout="vertical" state={stateResetPassword}>
            <SpanTitle>{I18n('reset_password', true)}</SpanTitle>
            <Email size="large" onChange={onChange} externalLabel stateName="resetPassword.email" />
            <Button
              block={true}
              size="large"
              style={{ minHeight: 45 }}
              type="primary"
              disabled={isDisabled()}
              onClick={() => onSubmit()}
              htmlType="submit"
            >
              {I18n('send', true)}
            </Button>
            <ForgotPassword
              onClick={() =>
                dispatch({
                  type: 'app.resetPassword',
                  ResetPassword: !app.ResetPassword,
                })
              }
            >
              {I18n('return', true)}
            </ForgotPassword>
          </Form>
        </Card>
      </Container>
    </ImageBackground>
  );
};
