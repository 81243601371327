import { useState, useCallback, useEffect } from 'react';
import { I18n } from 'i18n';

import { Table as TableUI, Button, Tag, Spin, Input} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEllipsisH, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PageContent, Drawer } from 'components';
import { GetDevices } from 'apollo/configuration';
import { Detail } from './detail-device';
import { DevicesForm } from './create-devices';

const { Column } = TableUI;

export function ListDevices() {
  const [codeId, setcodeId] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isDevicesForm, setIstDevicesForm] = useState(false);
  const { data: dataDevices, loading } = GetDevices();

  const onCloseDrawer = () => {
    setcodeId('');
    setVisible(false);
    setIstDevicesForm(false);
  };

  const onShowDrawer = useCallback((action, value) => {
    switch (action) {
      case 'devices_create':
        setcodeId('');
        setIstDevicesForm(true);
        break;
      case 'devices_detail':
        setcodeId(value);
        break;
      default:
    }
    setVisible(true);
  }, []);

  useEffect(() => {
    let result = [];
    dataDevices.forEach((rows) => {
      if (!rows.name.toLowerCase().includes(searchUser.toLowerCase())) return;
      result.push(rows);
    });
    setData(result);
  }, [dataDevices, searchUser, loading]);

  if (loading) return <Spin spinning={loading} />;

  return (
    <PageContent
      title={I18n('list_devices', true, true)}
      extra={[
        <Input.Search
          loading={loading}
          id="search"
          name="search"
          disabled={dataDevices.length > 10 ? false : true}
          onChange={(e) => setSearchUser(e.target.value)}
          placeholder={I18n('find_devices')}
          size="large"
          allowClear
          style={{ width: '60%' }}
        />,
        <Button
          type="primary"
          className="rk-button-primary"
          onClick={() => onShowDrawer('devices_create', data._id)}
        >
          {I18n('add', true)}
        </Button>,
      ]}
    >
      <TableUI
        pagination = {findpaginator()}
        dataSource={data}
        loading={loading}
        bordered
        rowKey={(data) => data._id}
        rowClassName={(record) => !record.active && 'disabled-row'}
        onRow={(data) => {
          return {
            onClick: () => onShowDrawer('devices_detail', data._id),
          };
        }}
      >
        {['name'].map((field) => (
          <Column
            title={I18n(field, true)}
            dataIndex={field}
            key={field}
            render={(key) => I18n(key, true)}
          />
        ))}
        <Column title={I18n('code', true)} dataIndex={'code'} key={I18n('code', true)} />
        <Column
          title={I18n('data_points_name', true)}
          dataIndex={'data_points_name'}
          key={I18n('data_points_name', true)}
          render={(key) => I18n(key, true)}
        />
        <Column
          title={I18n('type', true)}
          dataIndex={'type'}
          key={I18n('type', true)}
          render={(key) => I18n(key, true)}
          filters={Thefilters(data,'type')}
          onFilter={(value, record) => record['type'] === value}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <>
                {value && value === true ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                    {`  ${I18n('active', true)}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('inactive', true)}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
        <Column
          width={80}
          title={I18n('color', true)}
          dataIndex={'color'}
          key={'color'}
          render={(color) => <div style={{ width: 15, height: 15, background: color }} />}
        />
        <Column
          key="action"
          width={50}
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
      <Drawer
        showTitle={true}
        title={
          <>
            {!codeId && isDevicesForm && I18n('create_devices', true, true)}
            {codeId && isDevicesForm && I18n('update_devices', true, true)}
            {codeId && !isDevicesForm && I18n('device_information', true, true)}
          </>
        }
        placement="right"
        onClose={(e) => onCloseDrawer(e)}
        visible={visible}
        extra={[
          <div className="rk-drawer-actions">
            {codeId && !isDevicesForm && (
              <span onClick={() => setIstDevicesForm(!isDevicesForm)}>{I18n('edit', true)}</span>
            )}
            <button
              onClick={(e) => onCloseDrawer(e)}
              type="button"
              aria-label="Close"
              class="ant-drawer-close"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>,
        ]}
      >
        {!codeId && isDevicesForm && <DevicesForm onCloseDrawer={(e) => onCloseDrawer(e)} />}
        {codeId && isDevicesForm && (
          <DevicesForm _id={codeId} onCloseDrawer={(e) => onCloseDrawer(e)} />
        )}
        {codeId && !isDevicesForm && (
          <Detail codeId={codeId} onCloseDrawer={(e) => onCloseDrawer(e)} />
        )}
      </Drawer>
    </PageContent>
  );
}

function Thefilters(data,key){

  const getdata = data.map((element)=>(element[key]))

  const deleterepeat = [...new Set(getdata)]

  return deleterepeat.map((element)=>(
    {
      text: I18n(element,true),
      value: element
    }
  ))

}

function findpaginator(){
  return {
    pageSize: 5
  }
}