import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import useComponents from 'hooks/useComponents';
import { userConfigure } from 'apollo/configuration';

export function Save({ userId, onCloseDrawer }) {
  const [isLoading, setLoading] = useState(false);
  const {
    configuration: { user },
  } = useComponents();

  const handleClick = async (e) => {
    setLoading(true);

    const { data, error, message } = await userConfigure({
      ...user,
      roles: [...user.accessMobile, user.accesWebs],
      userId,
      nameUser: user.address?.split('@')[0],
    });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      onCloseDrawer();
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...user, userId })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true,
    roles = [];
  let { name, address, accessMobile = [], userId, accesWebs } = variables;
  if (typeof accesWebs !== 'undefined' || accessMobile.length) {
    roles = [...accessMobile, accesWebs];
  }
  if (!userId) {
    disabled = ![name, address].every((v) => v);
  } else {
    disabled = ![name, address, userId].every((v) => v);
  }
  if (!disabled) disabled = roles.length ? false : true;
  return disabled;
}
