let defaultState = {
  loading: false,
  error: '',
  sent: '',
};
const account = (state = defaultState, action) => {
  switch (action.type) {
    case 'account.loading':
      return Object.assign({}, state, { loading: action.loading, sent: null });
    case 'account.error':
      return Object.assign({}, state, { error: action.message, sent: null });
    case 'account.reset':
      return defaultState;
    default:
      return state;
  }
};

export default account;
