import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function DeviceState(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: STATE_DEVICES,
        variables,
        skip: skip(variables),
        refetchQueries: ['DevicesByCustomer', 'DeviceById'],
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            DeviceState: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { codeId } = variables;
  skip = ![codeId].every((v) => v);
  return skip;
}

const STATE_DEVICES = gql`
  mutation DeviceState($codeId: ID!) {
    DeviceState(codeId: $codeId) {
      error
      data {
        _id
        name
        code
        type
        location
        color
        data_point
        data_points_name
        active
      }
      message
    }
  }
`;
