const user = (state = { data: {} }, action) => {
  switch (action.type) {
    case 'user.data':
      return Object.assign({}, state, { data: action.user });
    case 'user.reset':
      return Object.assign({}, state, { data: {} });
    default:
      return state;
  }
};

export default user;
