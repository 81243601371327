import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';
import { GET_THRESHOLDS } from './thresholds';

export async function thresholdsConfigure({ min, max, color, name, codeId }) {
  let variables = { name, codeId, min, max, color };
  if (max === 'Infinity') delete variables.max;
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_THRESHOLDS,
        variables,
        skip: skip(variables),
        refetchQueries: [{ query: GET_THRESHOLDS }],
      })
      .then(
        ({
          data: {
            ThresholdsConfigure: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { name, codeId, min, max, color } = variables;
  console.log(name, codeId, min, max, color);
  if (max === 'Infinity') skip = ![name, codeId, min, color].every((v) => v);
  else skip = ![name, codeId, min, max, color].every((v) => v);
  return skip;
}

const CONFIGURE_THRESHOLDS = gql`
  mutation ThresholdsConfigure(
    $name: String!
    $codeId: String!
    $min: Float!
    $max: Float
    $color: String!
  ) {
    ThresholdsConfigure(name: $name, codeId: $codeId, min: $min, max: $max, color: $color) {
      error
      data
      message
    }
  }
`;
