import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportDailyResponseTemperatureLog() {
  const {
    environment: {
      weather: {
        historicDailyLog: { year, week, day, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = { ReportDailyResponseTemperatureLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { year, week, day, dataPoint },
    skip: skip({ year, week, day, dataPoint }),
  });
  let dataOverview = [];
  data.ReportDailyResponseTemperatureLog.forEach((rows) => {
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview || [] };
}

function skip(variables) {
  let skip = true;
  let { year, week, day, dataPoint } = variables;
  skip = ![year, week, day, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportDailyResponseTemperatureLog(
    $year: Int!
    $week: Int!
    $day: Int!
    $dataPoint: String!
  ) {
    ReportDailyResponseTemperatureLog: ReportDailyResponseTemperatureLog(
      year: $year
      week: $week
      day: $day
      dataPoint: $dataPoint
    ) {
      _id
      text
      color
      data {
        _id
        hour
        value
      }
    }
  }
`;
