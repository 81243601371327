import { useState } from 'react';
import { Button } from 'antd';
import { I18n } from 'i18n';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Password, Toast, Loading } from 'components';
import { setPassword } from 'apollo/account';
import useComponents from 'hooks/useComponents';
import { Container, Card, Form, SpanTitle, ImageBackground } from './style';

export const SetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const { setPassword: stateSetPassword, onChange } = useComponents();
  const { newPassword, confirmPassword } = stateSetPassword;

  const onSubmit = async () => {
    if (newPassword !== confirmPassword) {
      return Toast.error({
        key: 'error-set-password',
        message: I18n('passwords_are_not_the_same'),
      });
    }
    const { data, loading, error, message } = await setPassword(newPassword, token);
    if (error) Toast.error({ key: 'error-set-password', message: I18n(message) });
    if (data) {
      history.push('/');
      Toast.success({ key: 'success-set-password', message: I18n(message) });
    }
    setLoading(loading);
  };

  const isDisabled = () => {
    return stateSetPassword['confirmPassword-valid'] && stateSetPassword['newPassword-valid']
      ? false
      : true;
  };

  if (loading) return <Loading loading={loading} />;

  return (
    <ImageBackground>
      <Container>
        <Card>
          <Form layout="vertical" state={stateSetPassword}>
            <SpanTitle>{I18n('set_password', true)}</SpanTitle>
            <Password
              label={I18n('new_password')}
              size="large"
              onChange={onChange}
              externalLabel
              stateName="setPassword.newPassword"
            />
            <Password
              label={I18n('confirm_password')}
              size="large"
              onChange={onChange}
              externalLabel
              stateName="setPassword.confirmPassword"
            />
            <Button
              block={true}
              size="large"
              style={{ minHeight: 45 }}
              type="primary"
              disabled={isDisabled()}
              onClick={() => onSubmit()}
              htmlType="submit"
            >
              {I18n('send', true)}
            </Button>
          </Form>
        </Card>
      </Container>
    </ImageBackground>
  );
};
