import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

const MUTATION_CHANGEPASSWORD = gql`
  mutation ChangePassword {
    ChangePassword {
      error
      data {
        _id
        name
        username
        email
        roles
        language
        verified
        active
      }
      message
    }
  }
`;

export async function changePassword(password) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: MUTATION_CHANGEPASSWORD,
      })
      .then(
        ({
          data: {
            ChangePassword: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}
