import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useOverview() {
  const {
    environment: {
      maxandmin: { log },
    },
  } = useSelector(({ state }) => state);
  const { yearStart, yearEnd, weekStart, weekEnd, block, device } = log;
  const variables = { yearStart, yearEnd, weekStart, weekEnd };
  const { data = { SensorLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });
  let dataOverview = [];
  data.SensorLog.forEach((row) => {
    if (row.dataPoint !== block) return;
    if (row.name !== device) return;
    dataOverview.push(row);
  });

  return { loading, data: dataOverview };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ResponseBySensorLog($yearStart: Int!, $yearEnd: Int!, $weekStart: Int!, $weekEnd: Int!) {
    SensorLog: ReportResponseSensorsLog(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
    ) {
      _id
      date
      year
      week
      day
      name
      dataPoint
      max
      min
      hours
    }
  }
`;
