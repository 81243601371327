import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function DeviceConfigure(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_DEVICES,
        variables,
        skip: skip(variables),
        refetchQueries: ['DevicesByCustomer', 'DevicesById'],
      })
      .then(
        ({
          data: {
            DevicesConfigure: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { _id, name, code, type, active, color, data_point } = variables;
  if (_id) skip = ![_id, name, code, type, active, color, data_point].every((v) => v);
  else skip = ![name, code, type, active, color, data_point].every((v) => v);
  return skip;
}

const CONFIGURE_DEVICES = gql`
  mutation DevicesConfigure(
    $_id: ID
    $name: String!
    $code: String!
    $type: String!
    $data_point: String!
    $color: String!
    $active: Boolean!
  ) {
    DevicesConfigure(
      _id: $_id
      name: $name
      code: $code
      type: $type
      color: $color
      data_point: $data_point
      active: $active
    ) {
      error
      data {
        _id
        name
        code
        type
        location
        color
        data_point
        active
      }
      message
    }
  }
`;
