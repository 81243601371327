import { useState } from 'react';
import { Toast } from 'components';
import { I18n } from 'i18n';
import { Button, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { userRemove } from 'apollo/configuration';

export function Unassign({ userId, onCloseDrawer }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await userRemove({ userId });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      history.push('/configuration/users');
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_successfully', true),
      });
      onCloseDrawer();
    }
    setLoading(false);
  };
  return (
    <Popconfirm
      disabled={isLoading}
      title={`${I18n('unassign_user?', true)}`}
      onConfirm={(e) => handleClick(e)}
      okText={I18n('yes', true)}
      cancelText={I18n('no', true)}
    >
      <Button className="rk-button-primary" type="primary" ghost disabled={isDisabled({ userId })}>
        {I18n('unassign_user')}
      </Button>
    </Popconfirm>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { userId } = variables;
  disabled = ![userId].every((v) => v);
  return disabled;
}
