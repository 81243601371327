import { Drawer as DrawerAntd } from 'antd';
import PropTypes from 'prop-types';
import './style.less';

export const Drawer = (props) => {
  return (
    <DrawerAntd
      title={props.showTitle ? props.title : null}
      placement={props.placement}
      onClose={props.onClose}
      visible={props.visible}
      closeIcon={props.closeIcon}
      extra={props.extra}
    >
      {props.children}
    </DrawerAntd>
  );
};

Drawer.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
};
