import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function DataPointsRemove(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: REMOVE_DATA_POINTS,
        variables,
        skip: skip(variables),
        refetchQueries: ['DataPointsByCustomer'],
      })
      .then(
        ({
          data: {
            DataPointsRemove: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { codeId } = variables;
  skip = ![codeId].every((v) => v);
  return skip;
}

const REMOVE_DATA_POINTS = gql`
  mutation DataPointsRemove($codeId: ID!) {
    DataPointsRemove(codeId: $codeId) {
      error
      data {
        _id
        name
        customerId
        longitude
        latitude
      }
      message
    }
  }
`;
