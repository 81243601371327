import { useQuery, gql } from '@apollo/client';

export function GetDevices() {
  const { data = {}, loading } = useQuery(GET_DEVICESBYCUSTOMER);
  return { loading, data: data.DevicesByCustomer || [] };
}

const GET_DEVICESBYCUSTOMER = gql`
  query DevicesByCustomer {
    DevicesByCustomer {
      _id
      name
      code
      type
      location
      color
      data_point
      data_points_name
      active
    }
  }
`;
