/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { I18n } from 'i18n';
import { Input as AndtInput, Form } from 'antd';
import { redex } from '../../utils';
import PropTypes from 'prop-types';

const re = redex.email;

export const Email = (props) => {
  let {
    disabled,
    value,
    noFormItem,
    placeholder,
    name,
    className,
    size,
    style,
    touched,
    error,
    stateName,
  } = props;

  useEffect(() => {
    props.onChange(stateName, props.defaultValue, true);
    return () => {
      props.onChange(stateName, '', true);
    };
  }, [props.defaultValue]);

  if (noFormItem) {
    return (
      <AndtInput
        disabled={disabled}
        type="email"
        id="email"
        placeholder={placeholder}
        name={name}
        size={size || 'middle'}
        value={value}
        defaultValue={props.default}
        className={className}
        onChange={(e) => props.onChange(stateName, e.target.value, getValid(e.target.value))}
      />
    );
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      help={touched || error ? error : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      label={`${I18n('email')}:*`}
    >
      <AndtInput
        disabled={disabled}
        style={{ ...style }}
        type="email"
        size={size || 'middle'}
        placeholder={I18n('email')}
        value={value}
        defaultValue={props.default}
        className={className}
        onChange={(e) => props.onChange(stateName, e.target.value, getValid(e.target.value))}
      />
    </Form.Item>
  );
};

function getValid(value) {
  return value && re.test(value);
}

Email.propTypes = {
  noFormItem: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
