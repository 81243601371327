import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ReusablesActions from './actions/components';

export function componentsRedux(component) {
  function mapStateToProps(state, ownProps) {
    let values = ownProps.stateName.split('.').reduce((o, i) => o[i], state['state']);
    try {
      return {
        ...values,
        value: ownProps.stateName.split('.').reduce((o, i) => o[i], state['state']),
      };
    } catch (e) {
      console.log(`Error with "${ownProps.stateName}", that component not exist yet.`);
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReusablesActions, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(component);
}
