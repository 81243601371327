import styled from 'styled-components';
import ErrorPagesImg from 'assets/images/ErrorPages.svg';
import InternalServerErrorImg from 'assets/images/InternalServerError.svg';

export const ContentNotFound = styled.div`
  background: rgb(221, 226, 237);
  width: 100%;
  line-height: 1.4;
  flex: 1;
  justify-content: 'center';
  height: '100hv';
  text-align: center;
  z-index: 99999;
  position: absolute;
`;

export const ErrorPages = styled.div`
  max-width: 767px;
  max-height: 767px;
  height: 467px;
  margin: auto;
  background-image: url(${ErrorPagesImg});
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  z-index: 10000;
`;

export const InternalServerError = styled.div`
  max-width: 767px;
  max-height: 767px;
  height: 467px;
  margin: auto;
  background-image: url(${InternalServerErrorImg});
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
`;

export const H2 = styled.h2`
  font-size: 26px;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 25px;
  letter-spacing: 3px;
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: 200;
  margin-top: 0px;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  display: block;
  padding-top: 300px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 564px;
  height: 44px;
  > img,
  h3 {
    margin-bottom: 40px;
  }
  > h2 {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
  > h3 {
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  > button {
    width: 100%;
    height: 44px;
  }
`;

export const NoResultsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  padding: 100px;
  @media only screen and (max-width: 736px) {
    padding: 50px;
  }
`;
