import { useMemo } from 'react';
import { WaterPreparation } from './water-preparation';
import { FilterPressure } from './filter-pressure';
import { GravityFilter } from './gravity-filter';
import { FilterOrp } from './filter-orp';
import { FlowratesFilter } from './filter-flowrates';
import { FilterTankLevel } from './filter-tank-level';

export const useWater = () => {
  return useMemo(() => {
    return [
      {
        name: 'water-preparation',
        exact: true,
        path: 'water-preparation',
        component: WaterPreparation,
      },
      { name: 'filter-pressure', exact: true, path: 'filter-pressure', component: FilterPressure },
      { name: 'gravity-filter', exact: true, path: 'gravity-filter', component: GravityFilter },
      { name: 'filter-orp', exact: true, path: 'filter-orp', component: FilterOrp },
      {
        name: 'filter-flowrates',
        exact: true,
        path: 'filter-flowrates',
        component: FlowratesFilter,
      },
      {
        name: 'filter-tank-level',
        exact: true,
        path: 'filter-tank-level',
        component: FilterTankLevel,
      },
    ];
  }, []);
};
