import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function userState(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: REMOVE_USERS,
        variables,
        skip: skip(variables),
        refetchQueries: ['UserById', 'UsersByCustomer'],
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            UserState: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { userId } = variables;
  skip = ![userId].every((v) => v);
  return skip;
}

const REMOVE_USERS = gql`
  mutation UserState($userId: ID!) {
    UserState(userId: $userId) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;
