import { useEffect } from 'react';
import { GithubPicker } from 'react-color';
import { Form } from 'antd';
import PropTypes from 'prop-types';

export const ColorPicker = (props) => {
  useEffect(() => {
    props.onChange(props.stateName, props.defaultValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  if (props.noFormItem) {
    return (
      <div className="hue-color-picker">
        <GithubPicker
          color={props.value}
          onChangeComplete={(color) => props.onChange(props.stateName, color.hex)}
        />
      </div>
    );
  }

  return (
    <Form.Item
      label={`${props.placeholder}: ${props.value ? props.value : ''}`}
      labelCol={{ sm: { span: 4 } }}
      wrapperCol={{ sm: { span: 20 } }}
    >
      <div className="hue-color-picker">
        <GithubPicker
          color={props.value}
          onChangeComplete={(color) => props.onChange(props.stateName, color.hex)}
        />
      </div>
    </Form.Item>
  );
};

ColorPicker.propTypes = {
  noFormItem: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
};
