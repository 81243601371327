import React from 'react';
import { PageHeader, Spin, Button } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './style.less';

export function PageContent(props) {
  return (
    <div className="rk-page-container">
      <PageHeader
        className="rk-page-header"
        backIcon={[
          props.backIcon ? (
            <Button type="primary" icon={<FontAwesomeIcon icon={faArrowLeft} />} size="large" />
          ) : null,
        ]}
        onBack={() => props.onBack()}
        title={props.title}
        extra={props.extra}
      />
      {!props.loading ? (
        <div className="rk-page-content">{props.children}</div>
      ) : (
        <Spin spinning={props.loading} />
      )}
    </div>
  );
}

PageContent.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  extra: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
