import React from 'react';
import { InputRows } from 'components';
import SingleDate from '../../components/single-date/single-date';

export function Filters({ stateName }) {
  return (
    <InputRows>
      <SingleDate stateName={stateName} />
    </InputRows>
  );
}
