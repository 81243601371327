const devices = (state = [], action) => {
  switch (action.type) {
    case 'devices.data':
      return Object.assign([], state, [...action.devices]);
    case 'devices.reset':
      return Object.assign([], state, []);
    default:
      return state;
  }
};

export default devices;
