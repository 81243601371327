import { useState, useEffect } from 'react';
import { I18n } from 'i18n';
import { makeid } from 'utils';
import { Redirect, useHistory } from 'react-router-dom';
import { Select, PageContent } from 'components';
import { Table as TableUI, Button } from 'antd';
import { useSelector } from 'react-redux';
import { getThresholds } from 'apollo/configuration/customers/thresholds';
const { Column } = TableUI;
const stateName = 'configuration.threshold';

export function Thresholds() {
  const history = useHistory();
  const [useThreshold, setThreshold] = useState('');
  const [form, setform] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);

  const module = useSelector(
    ({
      state: {
        configuration: { threshold: name },
      },
    }) => name,
  );
  const dataSelect = Object.keys(result);
  const tableData = Object.values(result[module.name] || []) || [];

  useEffect(() => {
    async function dataCustomers() {
      setIsLoading(true);
      try {
        const { data, loading } = await getThresholds();
        setIsLoading(loading);
        setResult(data?.ThresholdsCustomer?.data);
      } catch (error) {
        setError(new Error(error.message));
      }
      setIsLoading(false);
    }
    dataCustomers();
  }, []);

  const onClick = (form, value, i) => {
    setform(form);
    setThreshold(i);
  };

  if (error) return history.push('/error-server');

  if (form) {
    return (
      <Redirect to={`/configuration/threshold/form?codeId=${makeid(50)}-${useThreshold}`} push />
    );
  }

  return (
    <>
      <PageContent
        title={I18n('list_threshold', true, true)}
        backIcon={false}
        extra={[
          <Select
            label={I18n('modules')}
            stateName={`${stateName}.name`}
            data={dataSelect.map((key) => ({
              value: key,
              name: I18n(key, true),
            }))}
            placeholder={I18n('modules')}
            default={module.name}
            style={{ width: 250 }}
          />,
        ]}
      >
        <TableUI
          dataSource={tableData}
          loading={isLoading}
          pagination={false}
          bordered
          rowKey={(_data, i) => i}
        >
          {['min', 'max'].map((field) => (
            <Column title={I18n(field, true)} dataIndex={field} key={field} />
          ))}
          <Column
            width={80}
            title={I18n('color', true)}
            dataIndex={'color'}
            key={'color'}
            render={(color) => <div style={{ width: 15, height: 15, background: color }} />}
          />
          <Column
            key="action"
            width={100}
            render={(_text, key, i) => [
              <Button
                size="small"
                label="edit"
                style={{ width: 75, height: 25, borderRadius: 0 }}
                onClick={() => onClick(true, key, i)}
                type="link"
              >
                {I18n('edit', true)}
              </Button>,
            ]}
          />
        </TableUI>
      </PageContent>
    </>
  );
}
