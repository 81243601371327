import PropTypes from 'prop-types';
import './style.less';

export const FormCard = (props) => {
  const { title, description, children, disabled } = props;
  return (
    <div className={`form-card row ${disabled ? 'form-card-disable' : ''}`}>
      <h2> {title}</h2>
      <p>{description}</p>
      <>{children}</>
    </div>
  );
};

FormCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};
