import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function DataPointsConfigure(variables) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: CONFIGURE_DATA_POINTS,
        variables,
        skip: skip(variables),
        refetchQueries: ['DataPointsByCustomer', "DataPointsByCodeId",  "CurrentCustomer", 'DataPointsByCustomer', 'DataPointsById'],
      })
      .then(
        ({
          data: {
            DataPointsConfigure: { error, data, message },
          },
          loading,
        }) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error({ error: true, message: error.message, data: {} }));
      });
  });
}

function skip(variables) {
  let skip = true;
  let { name, longitude, latitude, module, location } = variables;
  skip = ![name, longitude, latitude, module, location].every((v) => v);
  return skip;
}

const CONFIGURE_DATA_POINTS = gql`
  mutation DataPointsConfigure(
    $name: String!
    $longitude: Float!
    $latitude: Float!
    $module: String!
    $location: String!
    $time: Int
    $diameter: Float
    $yValuesMax: Float
    $threshold: JSON
    $codeId: ID
  ) {
    DataPointsConfigure(
      codeId: $codeId
      name: $name
      longitude: $longitude
      latitude: $latitude
      module: $module
      location: $location
      time: $time
      diameter: $diameter
      threshold: $threshold
      yValuesMax: $yValuesMax
    ) {
      error
      data {
        _id
        name
        customerId
        longitude
        latitude
        active
        module
        location
        time
        diameter
        threshold
        yValuesMax
      }
      message
    }
  }
`;
