const dataPoints = (state = [], action) => {
  switch (action.type) {
    case 'dataPoints.data':
      return Object.assign([], state, [...action.dataPoints]);
    case 'dataPoints.reset':
      return Object.assign([], state, []);
    default:
      return state;
  }
};

export default dataPoints;
