import React from 'react';
import { Button, Spin } from 'antd';
import { login } from 'apollo/account';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'i18n';
import { Email, Password, Toast } from 'components';
import useComponents from 'hooks/useComponents';
import { Container, Card, Form, SpanTitle, ImageBackground, ForgotPassword } from './style';

export function Login() {
  const { account, onChange } = useComponents();
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);

  const onSignIn = async () => {
    const { data, loading, error, message } = await login(account.email, account.password);
    dispatch({ type: 'account.loading', loading });
    if (error) Toast.error({ key: 'error-login', message: I18n(message, true) });
    if (data) {
      dispatch({ type: 'user.data', user: data });
      localStorage.setItem('auth:userid', data._id);
    }
    dispatch({ type: 'account.loading', loading });
  };

  const isDisabled = () => {
    return account['email-valid'] && account['password-valid'] ? false : true;
  };

  return (
    <Spin spinning={false} size="large">
      <ImageBackground>
        <Container>
          <Card>
            <Form layout="vertical" state={account}>
              <SpanTitle>{I18n('sign_In', true)}</SpanTitle>
              <Email size="large" onChange={onChange} externalLabel stateName="account.email" />
              <Password
                size="large"
                onChange={onChange}
                externalLabel
                stateName="account.password"
              />
              <Button
                block={true}
                style={{ minHeight: 55 }}
                type="primary"
                disabled={isDisabled()}
                onClick={() => onSignIn()}
                htmlType="submit"
              >
                {I18n('accept', true)}
              </Button>
              <ForgotPassword
                onClick={() =>
                  dispatch({
                    type: 'app.resetPassword',
                    ResetPassword: !app.ResetPassword,
                  })
                }
              >
                {I18n('forgot_password', true)}
              </ForgotPassword>
            </Form>
          </Card>
        </Container>
      </ImageBackground>
    </Spin>
  );
}
