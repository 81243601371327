import { useState, useCallback, useEffect } from 'react';
import { I18n } from 'i18n';
import { useHistory } from 'react-router-dom';
import { PageContent, Drawer } from 'components';
import { Table as TableUI, Button, Tag, Spin, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEllipsisH, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GetDataPoints } from 'apollo/configuration/';
import { Detail } from './detail-data-point';
import { DataPointForm } from './data-point-create';

const { Column } = TableUI;

export function DataPoints() {
  const history = useHistory();
  const [codeId, setcodeId] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [visible, setVisible] = useState(false);
  const [isDataPoints, setIsDataPointsForm] = useState(false);
  const [data, setData] = useState([]);
  const { data: dataDataPoints, loading, error } = GetDataPoints({ searchUser });

  const onCloseDrawer = () => {
    setcodeId('');
    setVisible(false);
    setIsDataPointsForm(false);
  };

  const onShowDrawer = useCallback((action, value) => {
    switch (action) {
      case 'data_points_create':
        setcodeId('');
        setIsDataPointsForm(true);
        break;
      case 'data_points_detail':
        setcodeId(value);
        break;
      default:
    }
    setVisible(true);
  }, []);

  if (error) history.push('/error-server');

  useEffect(() => {
    let result = [];
    dataDataPoints.forEach((rows) => {
      if (!rows.name.toLowerCase().includes(searchUser.toLowerCase())) return;
      result.push(rows);
    });
    setData(result);
  }, [dataDataPoints, searchUser, loading]);

  if (loading) return <Spin spinning={loading} />;

  return (
    <PageContent
      title={I18n('list_data_points', true, true)}
      extra={[
        <Input.Search
          loading={loading}
          disabled={dataDataPoints.length > 10 ? false : true}
          onChange={(e) => setSearchUser(e.target.value)}
          placeholder={I18n('find_data_point')}
          size="large"
          allowClear
          style={{ width: '60%' }}
        />,
        <Button
          type="primary"
          className="rk-button-primary"
          onClick={() => onShowDrawer('data_points_create')}
        >
          {I18n('add', true)}
        </Button>,
      ]}
    >
      <TableUI
        pagination={findpaginator()}
        dataSource={data}
        loading={loading}
        bordered
        rowKey={(data) => data._id}
        rowClassName={(record) => !record.active && 'disabled-row'}
        onRow={(data) => {
          return {
            onClick: () => onShowDrawer('data_points_detail', data._id),
          };
        }}
      >
        {['name', 'longitude', 'latitude'].map((field) => (
          <Column title={I18n(field, true)} dataIndex={field} key={field} />
        ))}
        <Column
          title={I18n('modules', true)}
          dataIndex={'module'}
          key={I18n('module', true)}
          render={(key) => (key ? I18n(key, true) : null)}
          filters={findFilters(data, 'module')}
          onFilter={(value, record) => record['module'] === value}
        />
        <Column
          title={I18n('location', true)}
          dataIndex={'location'}
          key={I18n('location', true)}
          render={(key) => (key ? I18n(key, true) : I18n('no_location'))}
        />
        <Column
          title={I18n('time_monitoring', true)}
          dataIndex={'time'}
          key={I18n('time', true)}
          render={(key) => key}
        />
        <Column
          title={I18n('diameter', true)}
          dataIndex={'diameter'}
          key={I18n('diameter', true)}
          render={(key) => (key ? key : I18n('not_apply'))}
        />
        <Column
          title={I18n('yValuesMax', true)}
          dataIndex={'yValuesMax'}
          key={I18n('yValuesMax', true)}
          render={(key) => (key !== 0 ? key : I18n('not_apply'))}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <>
                {value && value === true ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                    {`  ${I18n('active', true)}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('inactive', true)}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
      <Drawer
        showTitle={true}
        title={
          <>
            {!codeId && isDataPoints && I18n('create_data_points', true, true)}
            {codeId && isDataPoints && I18n('update_data_points', true, true)}
            {codeId && !isDataPoints && I18n('datapoint_information', true, true)}
          </>
        }
        placement="right"
        onClose={(e) => onCloseDrawer(e)}
        visible={visible}
        extra={[
          <div className="rk-drawer-actions">
            {codeId && !isDataPoints && (
              <span onClick={() => setIsDataPointsForm(!isDataPoints)}>{I18n('edit', true)}</span>
            )}
            <button
              onClick={(e) => onCloseDrawer(e)}
              type="button"
              aria-label="Close"
              class="ant-drawer-close"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>,
        ]}
      >
        {!codeId && isDataPoints && <DataPointForm onCloseDrawer={(e) => onCloseDrawer(e)} />}
        {codeId && isDataPoints && (
          <DataPointForm codeId={codeId} onCloseDrawer={(e) => onCloseDrawer(e)} />
        )}
        {codeId && !isDataPoints && (
          <Detail codeId={codeId} onCloseDrawer={(e) => onCloseDrawer(e)} />
        )}
      </Drawer>
    </PageContent>
  );
}

function findFilters(data, key) {

  const out= data.map((element)=>(element[key]))

  const deleteRepeat = [...new Set(out)]

  return deleteRepeat.map((element)=>(
    {
      text: I18n(element, true),
      value: element
    }
  ))

}

function findpaginator (){
  return {
    pageSize: 5
  }
}
