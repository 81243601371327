import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getCurrentMe } from 'apollo/account';

export function useMe() {
  const history = useHistory();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchQuery = async () => {
      const {
        data: { ProfileUser = {}, CurrentCustomer = {} },
        error,
      } = await getCurrentMe();
      if (error) return history.push('/');
      if (ProfileUser && ProfileUser._id !== null) {
        dispatch({ type: 'user.data', user: ProfileUser });
      }
      if (CurrentCustomer && CurrentCustomer._id !== null) {
        dispatch({ type: 'customer.data', customer: CurrentCustomer });
      }
      setLoading(false);
    };
    fetchQuery();
  }, [dispatch, history]);

  const signOut = () => {
    localStorage.clear();
    window.location.reload();
    dispatch({ type: 'user.reset' });
    dispatch({ type: 'customer.reset' });
    client.resetStore();
    history.push('/');
  };

  return {
    loading,
    signOut,
  };
}
