import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useOverviewGravityFiltersLog() {
  const {
    waterQuality: {
      gravityFilters: {
        register: { year, week, day, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  let variables = { week, year, day };
  const { data = { DataPressureLog: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables,
    skip: skip(variables),
  });

  let dataOverview = [];
  data.DataPressureLog.forEach((rows) => {
    if (rows.data_point !== dataPoint) return;
    dataOverview.push(rows);
  });
  return { loading, data: dataOverview };
}

function skip(variables) {
  let skip = true;
  let { year, week, day } = variables;
  skip = ![year, week, day].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ResponseGravityFiltersLog($year: Int!, $week: Int!, $day: Int!) {
    DataPressureLog: ResponseGravityFiltersLog(year: $year, week: $week, day: $day) {
      _id
      year
      week
      day
      exit_device_code
      data_point
      data_exit
      hours_apo
    }
  }
`;
