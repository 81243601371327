import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import useComponents from 'hooks/useComponents';
import { thresholdsConfigure } from 'apollo/configuration';

export default function SaveThresholds({ codeId }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const {
    configuration: { threshold },
  } = useComponents();

  const handleClick = async (e) => {
    setLoading(true);
    const { data, error, message } = await thresholdsConfigure({ ...threshold, codeId });
    if (error) return history.push('/error-server');
    if (!error && data) {
      history.push('/configuration/thresholds');
      Toast.success({ key: 'success-save-thresholds', message: I18n(message, true) });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        loading={isLoading}
        size="large"
        type="primary"
        style={{ height: 44, width: 147, fontWeight: 600, fontSize: 14 }}
        onClick={handleClick}
        disabled={isDisabled({ ...threshold, codeId })}
      >
        {I18n('accept', true)}
      </Button>
    </>
  );
}

function isDisabled(variables) {
  let disabled = true;
  let { codeId } = variables;
  disabled = ![codeId, variables['min-valid'], variables['max-valid']].every((v) => v);
  return disabled;
}
