import React from 'react';
import PropTypes from 'prop-types';
import './style.less';

export const ReportContainer = (props) => {
  const { children } = props;
  return <div className="report-container">{children}</div>;
};

ReportContainer.propTypes = {
  style: PropTypes.object,
};
