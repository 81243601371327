import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { faCheckDouble, faPlane, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { I18n } from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from 'apollo/account';
import client from 'apollo/apollo-client';
import { Toast } from 'components';
import { Tooltip } from 'antd';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Button, ListItem, ListItemMeta, Select, ContainerSelect } from './style';

const TYPE = {
  test: { color: '#77787E', icon: faListAlt },
  pilot: { color: '#EBC40E', icon: faPlane },
  client: { color: '#40CD87', icon: faCheckDouble },
};

export function ListCustormers() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { type } = useSelector(({ user: { data } }) => data);
  const [state, setState] = useState('pilot');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultFilter, setResultFilter] = useState([]);
  const [result, setResult] = useState([]);

  useEffect(() => {
    async function dataCustomers() {
      setIsLoading(true);
      try {
        const { data } = await getCustomers();
        setResult(data.CustomersByUser);
      } catch (error) {
        setError(new Error(error.message));
      }
      setIsLoading(false);
    }
    dataCustomers();
  }, []);

  const signOut = () => {
    localStorage.clear();
    window.location.reload();
    dispatch({ type: 'user.reset' });
    dispatch({ type: 'customer.reset' });
    client.resetStore();
    history.push('/');
  };

  const setCustomer = (customer) => {
    dispatch({ type: 'customer.data', customer });
    window.location.reload();
    localStorage.setItem('auth:customerId', customer._id);
  };

  useEffect(() => {
    if (result.length === 1) return setCustomer(result[0]);
    if (!['internal'].includes(type)) return setResultFilter(result);
    const dataFilter = result.filter((row) => row.type === state);
    setResultFilter(dataFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, result, type]);

  if (error) Toast.error({ key: 'error-customers', message: error });

  return (
    <div style={{ height: '100vh', overflowY: 'scroll', background: '#f2f4f6' }}>
      <List
        loading={isLoading}
        header={
          <h1>
            {I18n('customers', true)}

            <Button type="dashed" onClick={() => signOut()}>
              {I18n('logout', true)}
            </Button>
            {['internal'].includes(type) && (
              <ContainerSelect>
                <Select
                  defaultValue={state}
                  style={{ width: 120 }}
                  onChange={(value) => setState(value)}
                  options={Object.keys(TYPE).map((key) => ({ value: key, label: I18n(key, true) }))}
                />
                <Tooltip title={I18n('selected_customer_type')} color="#0f1c30">
                  <FontAwesomeIcon size="xs" icon={faQuestionCircle} />
                </Tooltip>
              </ContainerSelect>
            )}
          </h1>
        }
        bordered
        dataSource={resultFilter || []}
        renderItem={(customer, i) => {
          return (
            <ListItem
              className={!customer.active ? 'disabled-row' : ''}
              key={i}
              disabled={customer.active}
              onClick={() => setCustomer(customer)}
            >
              <ListItemMeta
                title={I18n(customer.name, true)}
                description={
                  <>
                    <span>{`${I18n('status', true)}:`}</span>
                    <span style={{ color: TYPE[customer.type].color }}>{`${I18n(
                      customer.type,
                      true,
                    )}`}</span>
                  </>
                }
              />
            </ListItem>
          );
        }}
      />
    </div>
  );
}
